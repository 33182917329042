@charset "utf-8";

/* 客户logo展示 */
.rno-1-logo-case {
  display: block;
  text-decoration: none;
  margin: 0 auto;
  max-width: 220px;
  height: 68px;
  overflow: hidden;
  text-align: center;
  line-height: 44px
}

.rno-1-logo-case img {
  max-width: 100%;
  max-height: 100%
}

@media screen and (min-width: 769px) {
  .rno-1-logo-cases .rno-lattice.rno-lattice-without-margin {
    margin-bottom: -98px
  }

  .rno-1-logo-cases .rno-lattice-cell {
    margin-bottom: 98px
  }

  .cooperative-partner-logo .rno-lattice .rno-lattice-cell {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .rno-1-logo-cases .rno-lattice.rno-lattice-without-margin {
    margin-bottom: -60px
  }

  .rno-1-logo-cases .rno-lattice-cell {
    margin-bottom: 60px
  }
}

@media screen and (max-width: 600px) {
  .rno-1-logo-cases .rno-lattice.rno-lattice-without-margin {
    margin-bottom: -35px
  }

  .rno-1-logo-cases .rno-lattice-cell {
    margin-bottom: 35px
  }

  .rno-1-logo-case {
    max-width: 88px;
    height: 30px;
  }
}

/* 追加在客户故事下的样式排版 s */
.rno-story-footer-cases {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 10px;
}

.rno-story-footer-cases .rno-1-logo-case {
  max-width: 185px;
  height: 48px;
}

.rno-story-footer-cases.rno-1-logo-cases .rno-lattice-cell {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .rno-story-footer-cases {
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;

    /* margin-top: -38px; */
  }
}

/* 追加在客户故事下的样式排版 e */

/* 经典客户案例 s */

/* .rno-classic-customer .rno-1-logo-case {
    max-width: 185px;
    height: 48px;
} */

/* @media screen and (max-width: 600px){
    .rno-classic-customer .rno-1-logo-case {
        max-width: 88px;
        height: 30px;
    }
} */

/* 经典客户案例 e */



/* 追加的样式 s */
.rno-section-subtitle {
  position: relative;
  margin-top: 32px;
  text-align: center;
  margin-bottom: 30px;
}

.rno-section-subtitle span {
  display: inline-block;
  background-color: #fff;
  position: relative;
  z-index: 5;
  padding: 0 40px;
  font-size: 14px;
  color: #999
}

.rno-section-subtitle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: hidden;
  background-color: #e5e5e5;
  background-image: linear-gradient(to right,#fff,#e4e4e4,#fff,#e4e4e4,#fff);
  top: 50%;
  left: 0
}

.cooperative-partner-logo .rno-section-subtitle span {
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .rno-1-logo-case {
    max-width: 100%;
    height: 36px;
  }

  .cooperative-partner-logo .rno-lattice {
    margin-left: -16px;
    margin-right: -16px;
  }

  .cooperative-partner-logo .rno-lattice.rno-lattice-cols-5 {
    margin-bottom: -30px;
  }

  .cooperative-partner-logo .rno-lattice.rno-lattice-cols-s-3 > .rno-lattice-cell {
    margin-bottom: 24px;
    margin-right: 0;
    width: calc(100%/ 3);

  }

  .cooperative-partner-logo .rno-section-subtitle {
    margin-top: 24px;
    margin-bottom: 20px;
  }
}
