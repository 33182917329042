.tvp-carousel-wrap .rno-section-inner {
  max-width: 100%;
}

.tvp-carousel {
  position: relative;

  /* padding-bottom: 34px; */
  overflow: hidden;
}

.tvp-carousel-slider {
  overflow: hidden;
}

.tvp-carousel-slider-list {
  position: relative;
  font-size: 0px;
  text-align: center;
  white-space: nowrap;
}

.tvp-carousel-slider-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tvp-carousel-slider-item.active {
  position: relative;
  z-index: 3;
}

.tvp-carousel-dots {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  font-size: 0px;
  text-align: center;
}

.tvp-carousel-dots li {
  display: inline-block;
  vertical-align: top;
  padding: 8px 0;
  width: 30px;
  height: 2px;
  box-sizing: content-box;
  cursor: pointer;
}

.tvp-carousel-dots li::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #d8d8d8;
}

.tvp-carousel-dots li.active::after {
  background-color: #00a4ff;
}

.tvp-carousel-dots li + li {
  margin-left: 10px;
}

.tvp-carousel-btn {
  position: absolute;
  top: 70px;
  width: 80px;
  height: 80px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iODBweCIgaGVpZ2h0PSI4MHB4IiB2aWV3Qm94PSIwIDAgODAgODAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aG92ZXLmgIE8L3RpdGxlPgogICAgPGRlZnM+CiAgICAgICAgPGNpcmNsZSBpZD0icGF0aC0xIiBjeD0iMzAiIGN5PSIzMCIgcj0iMzAiPjwvY2lyY2xlPgogICAgICAgIDxmaWx0ZXIgeD0iLTM5LjIlIiB5PSItMzUuOCUiIHdpZHRoPSIxNzguMyUiIGhlaWdodD0iMTc4LjMlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMiI+CiAgICAgICAgICAgIDxmZU9mZnNldCBkeD0iMCIgZHk9IjIiIGluPSJTb3VyY2VBbHBoYSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiPjwvZmVPZmZzZXQ+CiAgICAgICAgICAgIDxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjcuNSIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIj48L2ZlR2F1c3NpYW5CbHVyPgogICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMC40MDcyMjE3OTkgICAwIDAgMCAwIDAuNjM1ODY0ODE5ICAgMCAwIDAgMCAxICAwIDAgMCAwLjMwMTQ2NDE2MSAwIiB0eXBlPSJtYXRyaXgiIGluPSJzaGFkb3dCbHVyT3V0ZXIxIj48L2ZlQ29sb3JNYXRyaXg+CiAgICAgICAgPC9maWx0ZXI+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0iaG92ZXLmgIEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSLnvJbnu4QtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuMDAwMDAwLCAxMC4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Ik92YWwtNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTMwLjAwMDAwMCwgLTMwLjAwMDAwMCkgIj4KICAgICAgICAgICAgICAgIDx1c2UgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMSIgZmlsdGVyPSJ1cmwoI2ZpbHRlci0yKSIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgICAgICA8dXNlIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);

  /* border-radius: 50%; */
  z-index: 9;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: opacity .3s ease;
  transition: opacity .3s ease;
  transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.tvp-carousel-btn::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -18px;
  width: 19px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tvp-carousel:hover .tvp-carousel-btn {
  opacity: 1;
  visibility: visible;
  z-index: 9;
}

.tvp-carousel-btn.btn-prev {
  left: 20px;
}

.tvp-carousel-btn.btn-prev::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMTkgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5sZWZ0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IumhtemdojEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSLmtLvliqjmiqXpgZMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zOC4wMDAwMDAsIC0zODkuMDAwMDAwKSIgZmlsbD0iI2RkZCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IuW3pueureWktCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTAuMDAwMDAwLCA0MDcuMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC01MC4wMDAwMDAsIC00MDcuMDAwMDAwKSB0cmFuc2xhdGUoMjAuMDAwMDAwLCAzNzcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMzQuOTcwNTYyNywxOSBMMzQuOTcwNTYyNyw0MiBDMzQuOTcwNTYyNyw0Mi41NTIyODQ3IDM1LjQxODI3OCw0MyAzNS45NzA1NjI3LDQzIEMzNi41MjI4NDc1LDQzIDM2Ljk3MDU2MjcsNDIuNTUyMjg0NyAzNi45NzA1NjI3LDQyIEwzNi45NzA1NjI3LDE4IEMzNi45NzA1NjI3LDE3LjQ0NzcxNTMgMzYuNTIyODQ3NSwxNyAzNS45NzA1NjI3LDE3IEwxMS45NzA1NjI3LDE3IEMxMS40MTgyNzgsMTcgMTAuOTcwNTYyNywxNy40NDc3MTUzIDEwLjk3MDU2MjcsMTggQzEwLjk3MDU2MjcsMTguNTUyMjg0NyAxMS40MTgyNzgsMTkgMTEuOTcwNTYyNywxOSBMMzQuOTcwNTYyNywxOSBaIiBpZD0ibGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjMuOTcwNTYzLCAzMC4wMDAwMDApIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0yMy45NzA1NjMsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
}

.tvp-carousel-btn.btn-prev:hover::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMTkgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5sZWZ0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IumhtemdojEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSLmtLvliqjmiqXpgZMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zOC4wMDAwMDAsIC0zODkuMDAwMDAwKSIgZmlsbD0iIzAwQUZGRSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IuW3pueureWktCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTAuMDAwMDAwLCA0MDcuMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC01MC4wMDAwMDAsIC00MDcuMDAwMDAwKSB0cmFuc2xhdGUoMjAuMDAwMDAwLCAzNzcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMzQuOTcwNTYyNywxOSBMMzQuOTcwNTYyNyw0MiBDMzQuOTcwNTYyNyw0Mi41NTIyODQ3IDM1LjQxODI3OCw0MyAzNS45NzA1NjI3LDQzIEMzNi41MjI4NDc1LDQzIDM2Ljk3MDU2MjcsNDIuNTUyMjg0NyAzNi45NzA1NjI3LDQyIEwzNi45NzA1NjI3LDE4IEMzNi45NzA1NjI3LDE3LjQ0NzcxNTMgMzYuNTIyODQ3NSwxNyAzNS45NzA1NjI3LDE3IEwxMS45NzA1NjI3LDE3IEMxMS40MTgyNzgsMTcgMTAuOTcwNTYyNywxNy40NDc3MTUzIDEwLjk3MDU2MjcsMTggQzEwLjk3MDU2MjcsMTguNTUyMjg0NyAxMS40MTgyNzgsMTkgMTEuOTcwNTYyNywxOSBMMzQuOTcwNTYyNywxOSBaIiBpZD0ibGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjMuOTcwNTYzLCAzMC4wMDAwMDApIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0yMy45NzA1NjMsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
}

.tvp-carousel-btn.btn-next {
  right: 20px;
}

.tvp-carousel-btn.btn-next::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMTkgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5yaWdodDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSLpobXpnaIxIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0i5rS75Yqo5oql6YGTIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTg2My4wMDAwMDAsIC0zODkuMDAwMDAwKSIgZmlsbD0iI2RkZCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IuWPs+eureWktCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTg0MC4wMDAwMDAsIDM3Ny4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0zNC45NzA1NjI3LDE5IEwzNC45NzA1NjI3LDQyIEMzNC45NzA1NjI3LDQyLjU1MjI4NDcgMzUuNDE4Mjc4LDQzIDM1Ljk3MDU2MjcsNDMgQzM2LjUyMjg0NzUsNDMgMzYuOTcwNTYyNyw0Mi41NTIyODQ3IDM2Ljk3MDU2MjcsNDIgTDM2Ljk3MDU2MjcsMTggQzM2Ljk3MDU2MjcsMTcuNDQ3NzE1MyAzNi41MjI4NDc1LDE3IDM1Ljk3MDU2MjcsMTcgTDExLjk3MDU2MjcsMTcgQzExLjQxODI3OCwxNyAxMC45NzA1NjI3LDE3LjQ0NzcxNTMgMTAuOTcwNTYyNywxOCBDMTAuOTcwNTYyNywxOC41NTIyODQ3IDExLjQxODI3OCwxOSAxMS45NzA1NjI3LDE5IEwzNC45NzA1NjI3LDE5IFoiIGlkPSJyaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjMuOTcwNTYzLCAzMC4wMDAwMDApIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0yMy45NzA1NjMsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
}

.tvp-carousel-btn.btn-next:hover::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIzNnB4IiB2aWV3Qm94PSIwIDAgMTkgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU0LjEgKDc2NDkwKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5yaWdodDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSLpobXpnaIxIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0i5rS75Yqo5oql6YGTIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTg2My4wMDAwMDAsIC0zODkuMDAwMDAwKSIgZmlsbD0iIzAwQUZGRSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IuWPs+eureWktCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTg0MC4wMDAwMDAsIDM3Ny4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0zNC45NzA1NjI3LDE5IEwzNC45NzA1NjI3LDQyIEMzNC45NzA1NjI3LDQyLjU1MjI4NDcgMzUuNDE4Mjc4LDQzIDM1Ljk3MDU2MjcsNDMgQzM2LjUyMjg0NzUsNDMgMzYuOTcwNTYyNyw0Mi41NTIyODQ3IDM2Ljk3MDU2MjcsNDIgTDM2Ljk3MDU2MjcsMTggQzM2Ljk3MDU2MjcsMTcuNDQ3NzE1MyAzNi41MjI4NDc1LDE3IDM1Ljk3MDU2MjcsMTcgTDExLjk3MDU2MjcsMTcgQzExLjQxODI3OCwxNyAxMC45NzA1NjI3LDE3LjQ0NzcxNTMgMTAuOTcwNTYyNywxOCBDMTAuOTcwNTYyNywxOC41NTIyODQ3IDExLjQxODI3OCwxOSAxMS45NzA1NjI3LDE5IEwzNC45NzA1NjI3LDE5IFoiIGlkPSJyaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjMuOTcwNTYzLCAzMC4wMDAwMDApIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0yMy45NzA1NjMsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
}


/* 活动 s */
.tvp-carousel.carousel-activity .tvp-carousel-slider {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  transform: translateX(-50%); 	/* IE 9 */
  transform: translateX(-50%); 	/* Firefox */
  transform: translateX(-50%); /* Safari 和 Chrome */
  transform: translateX(-50%); 	/* Opera */
}

.tvp-carousel.carousel-activity .tvp-activity-card {
  position: relative;
  top: 0px;
  overflow: hidden;
}

.tvp-carousel.carousel-activity .tvp-carousel-slider-item {
  box-sizing: border-box;
}

.tvp-carousel.carousel-activity .tvp-activity-card-object {
  opacity: .6;
}

.tvp-carousel.carousel-activity .tvp-carousel-slider-item.active .tvp-activity-card-object,
.tvp-carousel.carousel-activity .tvp-carousel-slider-item.active .tvp-activity-card-main {
  opacity: 1;
}

.tvp-carousel.carousel-activity .tvp-carousel-slider-item.active .tvp-activity-card {
  box-shadow: 0 4px 7px 0 #e6e9ed;
}

.tvp-carousel.carousel-activity .tvp-carousel-slider-item {
  transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease;
}

.tvp-carousel.carousel-activity .tvp-activity-card-object {
  transition: opacity .5s ease;
  transition: opacity .5s ease;
  transition: opacity .5s ease;
  transition: opacity .5s ease
}

@media screen and (min-width: 769px) {
  .tvp-carousel.carousel-activity {
    padding-bottom: 40px;
  }

  .tvp-carousel.carousel-activity .tvp-carousel-slider {
    padding-top: 12px;
    width: 2070px;
    min-height: 220px;
  }

  .tvp-carousel.carousel-activity .tvp-carousel-slider-item {
    padding: 0 35px;
    width: 690px;
  }

  .tvp-carousel.carousel-activity .tvp-carousel-slider-item.active {
    transform: scale(1.1224, 1.1224);
    transform: scale(1.1224, 1.1224); 	/* IE 9 */
    transform: scale(1.1224, 1.1224); 	/* Firefox */
    transform: scale(1.1224, 1.1224); /* Safari 和 Chrome */
    transform: scale(1.1224, 1.1224); 	/* Opera */
  }

  .tvp-carousel.carousel-activity .tvp-activity-card-object-box {
    padding-top: 196px;
  }

  .tvp-carousel.carousel-activity .tvp-activity-card-main {
    position: absolute;
    left: 285px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }

  /* .tvp-carousel.carousel-activity .tvp-activity-card{
        box-shadow: 0 4px 7px 0 #E6E9ED;
    } */

  /* .tvp-carousel.carousel-activity .tvp-carousel-dots {
        display: none;
    } */
  .tvp-carousel-dots li:hover::after {
    background-color: #0052D9;
  }
}

@media screen and (max-width: 768px) {
  .tvp-carousel.carousel-activity .tvp-carousel-slider-item.active .tvp-activity-card {
    box-shadow: none;
  }

  .tvp-carousel.carousel-activity {
    padding-bottom: 10px;
  }

  .tvp-carousel.carousel-activity .tvp-carousel-slider {
    padding-top: 10px;
    width: 696px;
    min-height: 342px;
  }

  .tvp-carousel.carousel-activity .tvp-carousel-slider-item {
    padding: 0 26px;
    width: 232px;
  }

  .tvp-carousel.carousel-activity .tvp-carousel-slider-item.active {
    transform: scale(1.0619, 1.0619);
    transform: scale(1.0619, 1.0619); 	/* IE 9 */
    transform: scale(1.0619, 1.0619); 	/* Firefox */
    transform: scale(1.0619, 1.0619); /* Safari 和 Chrome */
    transform: scale(1.0619, 1.0619); 	/* Opera */
  }

  .tvp-carousel.carousel-activity .tvp-activity-card {
    height: 322px;
  }

  .tvp-carousel.carousel-activity .tvp-carousel-btn-group {
    display: none;
  }

  .tvp-carousel-dots {
    bottom: -8px;
  }
}

/* 活动 e */


.tvp-activity-card {
  display: block;
  text-align: left;
  box-sizing: border-box;
  background: #fff;
}

.tvp-activity-card:hover .tvp-activity-card-tit {
  color: #0052D9;
}

.tvp-activity-card-object-box {
  position: relative;
  width: 100%;
  height: 0px;
}

.tvp-activity-card-image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.tvp-activity-card-main {
  padding: 20px;
  box-sizing: border-box;
  opacity: .6;
}

.tvp-activity-card-tit {
  line-height: 28px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  max-height: 28px;
  display: box;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tvp-activity-card-date {
  line-height: 28px;
  font-size: 14px;
  color: #999;
  font-weight: 500;
  max-height: 28px;
  display: box;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
}

.tvp-activity-card-desc {
  margin-top: 12px;
  line-height: 22px;
  font-size: 14px;
  color: #666;
  text-align: justify;
  max-height: 88px;
  display: box;
  box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tvp-activity-card-btnwrap {
  margin-top: 20px;
}

@media screen and (min-width: 769px) {
  .tvp-activity-card {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .tvp-activity-card-object {
    display: table-cell;
    vertical-align: top;
    width: 285px;
  }

  .tvp-activity-card-object-box {
    padding-top: 68.7719%;
  }

  .tvp-activity-card-main {
    display: table-cell;
    vertical-align: top;
  }
}

@media screen and (max-width: 768px) {
  .tvp-activity-card-object-box {
    padding-top: 68.8889%;
  }

  .tvp-activity-card-main {
    padding: 20px;
    background: #f5f7fa;
  }

  .tvp-activity-card-tit {
    line-height: 18px;
    font-size: 14px;
    max-height: 54px;
    -webkit-line-clamp: 2;
  }

  .tvp-activity-card-desc {
    line-height: 16px;
    font-size: 12px;
    color: #9b9b9b;
    height: 64px;
    -webkit-line-clamp: 4;
  }

  .tvp-activity-card-btnwrap {
    margin-top: 10px;
  }

  .tvp-activity-card-btnwrap .c-btn {
    padding: 0 10px;
    min-width: 68px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 9px;
  }

  .tvp-carousel-wrap .rno-section-inner {
    padding-left: 0;
    padding-right: 0;
  }
}