/** 样式扩展 **/
.partner-index-plan .rno-14-media-panel-title {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.partner-index-plan .rno-14-media-panel-desc {
  margin-top: 4px;
  height: 48px;
  max-height: 48px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: box;
  -webkit-line-clamp: 2; 
  box-orient: vertical;
}

.partner-index-plan .rno-21-media-panel-btn-wrap {
  margin-top: 20px;
}

.partner-index-plan .rno-21-media-panel-btn-wrap .rno-link-arrow {
  margin-left: 0;
}

.partner-index-plan-link.rno-link {
  margin-left: 8px;
  color: #999;
  text-decoration: none;
}

.partner-index-plan-link.rno-link:hover {
  color: #0052D9;
}

@media screen and (min-width: 768px) {
  .partner-index-plan .rno-tabs .rno-tabs-content {
    padding-top: 28px;
  }

  .partner-index-plan .rno-tabs .rno-tabs-list {
    border: 0;
  }

  .partner-index-plan .rno-tabs .rno-tabs-active .rno-tabs-item-con:after {
    content: none;
  }

  .partner-index-plan .rno-tabs .rno-tabs-item-con {
    line-height: 48px;
    border-radius: 22.5px;
    padding: 0 60px;
    box-sizing: border-box;
    background-image: linear-gradient(179deg, #F3F5F8 0%, #FFFFFF 100%);
    color: #333333;
    border: 2px solid #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0,36,92,0.10);
  }

  .partner-index-plan .rno-tabs .rno-tabs-active .rno-tabs-item-con {
    border-color: rgba(0, 164, 255, .08);
    color: #0052D9;
    background-image: linear-gradient(0deg, rgba(0,38,102,0.08) 0%, rgba(0,24,63,0.10) 100%);
    border: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 768px) {
  .partner-index-plan .partner-2-intro-card-tag {
    display: none;
  }

  .partner-index-plan .rno-14-media-panel {
    padding-top: 15px;
    padding-bottom: 18px;
  }

  .partner-index-plan .rno-21-media-panel-btn-wrap .rno-link {
    display: inline-block;
    vertical-align: middle;
  }

  .partner-index-plan .rno-21-media-panel-btn-wrap {
    width: auto;
  }

  .partner-index-plan .rno-14-media-panel-title {
    font-size: 14px;
    line-height: 24px;
    color: #2b2b2b;
  }

  .rno-14-media-panel:hover .rno-14-media-panel-title {
    color: #0052d9;
  }

  .partner-index-plan .rno-tabs-bar {
    display: block;
  }

  .partner-index-plan .rno-section-inner {
    padding: 0;
  }

  .partner-index-plan .rno-tabs-content {
    padding: 0 16px;
    padding-top: 25px;
  }

  .partner-index-plan .rno-tabs-item-con {
    padding-bottom: 14px;
    color: #444;
  }

  .partner-index-plan .rno-tabs-active .rno-tabs-item-con {
    color: #0052D9;
  }

  .partner-index-plan .rno-tabs-active .rno-tabs-item-con:after {
    bottom: 0;
    height: 2px;
  }
  .more-partner-wrap .rno-tabs-bar {
    display: block;
  }
}

.partners-plan-hero .rno-3-hero .rno-3-hero-item-desc {
  height: auto;
  -webkit-line-clamp: inherit; 
  overflow: visible;
  word-wrap: normal;
  max-height: none;
}

@media screen and (max-width: 768px) {
  .partners-plan-hero .rno-3-hero-list .rno-3-hero-item-btns {
    text-align: center;
  }

  .partners-plan-hero .rno-3-hero .rno-3-hero-item-desc {
    max-height: none;
  }

  .partners-plan-hero .rno-3-hero .rno-3-hero-item {
    min-height: 248px;
    padding-bottom: 20px;
    height: 100%;
  }
}

.partner-plan-table .date-table-info-head th,
.date-table-info-body td {
  padding: 20px 90px;
}

.partner-plan-table  .table-info-wrap tr td {
  text-align: right;
  border-left: 0;
}

.partner-plan-table  .table-info-wrap tr td:first-child {
  text-align: left;
}

.partner-plan-table  .date-table-info-head-wrap tr th {
  text-align: right;
}

.partner-plan-table  .date-table-info-head-wrap tr th:first-child {
  text-align: left;
}

.partner-plan-table2.rno-section {
  padding-top: 0;
}

@media screen and (max-width: 768px) {
  .partner-plan-table .date-table-info-head th,
  .date-table-info-body td {
    padding: 8px 20px;
  }

  .partner-plan-table .table-info-wrap {
    width: auto;
  }

  .partner-plan-table  .table-info-wrap tr td {
    text-align: center;
  }

  .partner-plan-table  .table-info-wrap tr td:first-child {
    text-align: center;
  }

  .partner-plan-table  .date-table-info-head-wrap tr th {
    text-align: center;
  }

  .partner-plan-table  .date-table-info-head-wrap tr th:first-child {
    text-align: center;
  }
}

/* 
.partner-plan-step-img{
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
}
.partner-plan-step-img img{
    width: 48px;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
}
.partner-plan-step .rno-19-media-panel-step{
    display: none;
}
.partner-plan-step .rno-19-media-panel{
    text-align: center;
}
.partner-plan-step .rno-19-media-panel-body{
    width: auto;
}
@media screen and (max-width: 768px) {
    .partner-plan-step-img{
        display: none;
    }
    .partner-plan-step .rno-19-media-panel-step{
        display: table-cell;
    }
    .partner-plan-step .rno-19-media-panel{
        text-align: left;
    }
    .partner-plan-step .rno-19-media-panel-body{
        width: 100%;
    }
} */
.partner-plan-step .rno-19-media-panel-step {
  padding-left: 0;
  position: relative;
}

.partner-plan-step .rno-19-media-panel-num {
  width: 100%;
  text-align: center;
  margin-left: 0;
}

.partner-plan-step .rno-19-media-panel-line {
  position: absolute;
}

.partner-plan-step .rno-19-media-panel-title {
  text-align: center;
}

.partner-plan-step .partner-plan-step-img {
  height: 80px;
  position: relative;
}

.partner-plan-step .partner-plan-step-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.partner-plan-step .rno-19-media-panel-body {
  width: 100%;
}

.partner-plan-step .rno-19-media-panel-line {
  left: 60%;
  top: 16px;
  width: 82%;
}

@media screen and (max-width: 768px) {
  .partner-plan-step .rno-19-media-panel-step {
    padding-left: 34px;
    position: relative;
  }

  .partner-plan-step .rno-19-media-panel-num {
    width: auto;
    text-align: left;
    margin-left: -34px;
  }

  .partner-plan-step .rno-19-media-panel-line {
    position: absolute;
  }

  .partner-plan-step .rno-19-media-panel-title {
    text-align: left;
  }

  .partner-plan-step .partner-plan-step-img {
    display: none;
  }

  .partner-plan-step .rno-19-media-panel-body {
    width: 100%;
  }

  .partner-plan-step .rno-19-media-panel-line {
    left: auto;
    top: auto;
    width: 100%;
  }
}
