@charset "utf-8";

/* 预防和解决突发事件  合作伙伴第二种形态 */
.rno-14-media-panel {
  box-sizing: border-box;
  padding: 20px;
  display: block;
  font-size: 14px;
  transition: all .2s linear;
  background-color: #fff;
  text-decoration: none;
  position: relative;

  /* top: 0; */
  box-shadow: 0 2px 4px rgba(3,27,78,.06);
  border: 1px solid #e5e8ed;
  text-align: left;
}

.rno-lattice-cell {
  background-size: cover;
}

@media screen and (min-width: 769px) {
  .rno-14-media-panel[href]:hover {
    box-shadow: 0 4px 8px rgba(3,27,78,.12);

    /* top: -3px; */
  }

}

.rno-14-media-panel-object {
  width: 100%;
  height: 68px;
  text-align: center;
  line-height: 48px;
}

/* .rno-14-media-panel-left {
    text-align: left;
}
.rno-14-media-panel-left .rno-14-media-panel-object {
    margin-left: 0;
} */
.rno-14-media-panel-icon,
.rno-14-media-panel-icon-m {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.rno-14-media-panel-title {
  font-size: 18px;
  line-height: 28px;
  color: #333;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rno-14-media-panel-desc {
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}


/* 合作伙伴 一行一列情况 s */
@media screen and (min-width: 769px) {
  .rno-14-media-panel.rno-only-panel {
    display: table;
    width: 100%;
  }

  .rno-only-panel .rno-14-media-panel-object {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding-right: 20px;
  }

  .rno-only-panel .rno-14-media-panel-body {
    display: table-cell;
  }

  .rno-14-media-panel-object .rno-14-media-panel-icon-m {
    display: none;
  }
}

/* 合作伙伴 一行一列情况 e */



/* 追加的样式 */

.rno-14-media-panel-body {
  border-top: 1px solid #e2e6ed;
  padding-top: 12px;
}

@media screen and (max-width: 768px) {
  .rno-14-media-panel-object {
    width: 186px;
    height: 48px;
  }

  .rno-14-media-panel-body {
    border-top: none;
  }

  .client-case-wrap .rno-tabs-simple {
    margin-left: 0;
    margin-right: 0;
  }


  .client-case-wrap .rno-tabs-simple-tabs,
  .client-case-wrap .rno-tabs-bar {
    display: block;
  }

  .client-case-wrap .rno-tabs-list {
    font-size: 0;
    white-space: nowrap;
    border-bottom: none;
  }

  .client-case-wrap .rno-tabs-bar {
    margin-left: -16px;
    margin-right: -16px;
    overflow: auto;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(229, 229, 229);
  }

  .client-case-wrap .rno-tabs-simple {
    border-top: none;
  }

  .client-case-wrap .rno-tabs-item.rno-tabs-active {
    border-bottom: 1px solid #0052D9;
  }
    
  .rno-header-subnav-tabs {
    padding: 0;
  }

  .rno-scheme-section .rno-lattice-cell:last-child {
    margin-bottom: 0;
  }

  .rno-14-media-panel-object .rno-14-media-panel-icon {
    display: none;
  }



}