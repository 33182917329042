@charset "utf-8";

/* 顶部提示条 */
.top-warning {
  width: 100%;
  box-sizing: border-box;
  position: static;
  background-color: #e4eff5;
  color: #333;
  text-align: center;
  margin-bottom: 0;
}