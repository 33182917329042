.partners-hot-selling .rno-section-bd {
  font-size: 0;
  display: box;
  display: flex;
  display: flex;
  box-align: stretch;
  align-items: stretch;
  align-items: stretch;
}

.partners-hot-selling .partners-hot-selling-species {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 20px);
}

@media screen and (min-width: 769px) {
  .partners-hot-selling .partners-hot-selling-species + .partners-hot-selling-species {
    margin-left: 40px;
    position: relative;
  }

  .partners-hot-selling .partners-hot-selling-species + .partners-hot-selling-species::before {
    content: '';
    position: absolute;
    top: 44px;
    bottom: 0;
    left: -20px;
    width: 1px;
    background-color: #e5e8ed;
  }
}

.partners-hot-selling .partners-hot-selling-species-head {
  margin-bottom: 12px;
  display: box;
  display: flex;
  display: flex;
  box-align: baseline;
  align-items: baseline;
  align-items: baseline;
}

.partners-hot-selling .partners-hot-selling-species-head .rno-link-arrow::after {
  display: none;
}

.partners-hot-selling .partners-hot-selling-species-head a {
  margin-left: 12px;
  flex-shrink: 0;
  flex-shrink: 0;
}

.partners-hot-selling .partners-hot-selling-species-tit {
  display: inline-block;
  vertical-align: baseline;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partners-hot-selling .partners-hot-selling-species-con {
  /* height: calc(100% - 44px); */
  display: box;
  display: flex;
  display: flex;
  box-orient: vertical;
  box-direction: normal;
  flex-direction: column;
  flex-direction: column;
  box-shadow: 0 2px 14px 0 rgba(0,36,92,0.10);
  background-image: linear-gradient(0deg, #F3F5F8 0%, #FFFFFF 100%);
  border: 2px solid #FFFFFF;
}

.partners-hot-selling .partners-hot-selling-card {
  min-height: 314px;
  box-flex: 1;
  flex-grow: 1;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  text-decoration: none;
  position: relative;
  transition: all .2s linear;
  transition: all .2s linear;
  display: box;
  display: flex;
  display: flex;
  box-orient: vertical;
  box-direction: normal;
  flex-direction: column;
  flex-direction: column;
}

.partners-hot-selling .partners-hot-selling-card + .partners-hot-selling-card {
  margin-top: 20px;
}

.partners-hot-selling .partners-hot-selling-card-tit {
  font-size: 16px;
  color: #333;
  line-height: 26px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partners-hot-selling .partners-hot-selling-trait-list {
  margin-top: 8px;
  box-flex: 1;
  flex: 1 1 auto;
  flex: 1 1 auto;
}

@media screen and (min-width: 769px) {
  .partners-hot-selling .partners-hot-selling-trait-list {
    min-height: 186px;
  }
}

.partners-hot-selling .partners-hot-selling-trait-item {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

.partners-hot-selling .partners-hot-selling-trait-item + .partners-hot-selling-trait-item {
  margin-top: 6px;
}

.partners-hot-selling .partners-hot-selling-trait-item em {
  font-weight: 500;
  color: #333;
}

.partners-hot-selling .partners-hot-selling-card-options {
  margin-top: 16px;
}

@media screen and (min-width: 769px) {
  .partners-hot-selling .partners-hot-selling-card-options .rno-btn {
    width: 100%;
    max-width: 200px;
  }
}

.partners-hot-selling .partners-hot-selling-card-options .rno-link {
  margin-left: 12px;
}

@media screen and (max-width: 768px) {
  .partners-hot-selling .rno-section-bd {
    display: block;
  }

  .partners-hot-selling .partners-hot-selling-species {
    width: 100%;
  }

  .partners-hot-selling .partners-hot-selling-species + .partners-hot-selling-species {
    margin-left: 0;
    margin-top: 20px;
  }

  .partners-hot-selling .partners-hot-selling-card {
    padding: 12px;
    min-height: 0;
  }

  .partners-hot-selling .partners-hot-selling-card + .partners-hot-selling-card {
    margin-top: 12px;
  }
}
