@charset "utf-8";

/* banner 主样式 */
.rno-3-hero {
  position: relative;
  height: 420px;
}

.rno-3-hero-list {
  height: 100%;
  background-color: #0e182b;
  overflow: hidden;
}

.rno-3-hero-item {
  position: relative;
  height: 100%;
  padding: 0 10px;
  background-size: cover;
}

.rno-3-hero-item-bg,
.rno-3-hero-main-body {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 420px;
  background-position: center top;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.rno-3-hero-embellish {
  position: absolute;
  left: 50%;
  margin-left: 115px;
  top: 0;
  width: 845px;
  height: 420px;
  background-size: cover;
}

/* 右侧区域色块填充 s */
.rno-3-hero-bg-color {
  width: calc( (100% - 1200px) / 2);
  height: 420px;
  background-color: #0e182b;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 1800px) {
  .rno-3-hero-bg-color {
    display: none;
  }
}

/* 右侧区域色块填充 e */

/* banner内容区域 */
.rno-3-hero-item-inner {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  padding: 92px 10px 0;
}

.rno-3-hero-item-ctn {
  max-width: 650px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  position: relative;
  z-index: 5;
}


.rno-3-hero-item-title {
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 16px;
  display: box;
  -webkit-line-clamp: 1;
  box-orient: vertical;
  overflow: hidden;
  max-height: 56px;
  word-wrap: break-word;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;

  /* margin-top: 20px; */
}

.rno-3-hero-item-desc {
  line-height: 26px;
  font-size: 16px;
  color: #fff;
  display: box;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;
  height: 52px;
  word-wrap: break-word;    
  -webkit-font-smoothing: antialiased;
  max-width: 580px;
}

/* 按钮微调 */
.rno-3-hero-item-btns {
  font-size: 0;
  margin-top: 20px;
}

.rno-3-hero-item-btns .rno-btn {
  width: 140px;
  border-radius: 2px;
  margin-right: 12px;
  font-size: 14px;
}

.rno-3-hero-item-btns .rno-btn:hover {
  transition: all .2s linear;
}

.rno-3-hero-item-btns .rno-btn-weak {
  color: #fff;
  border-color: rgba(255, 255, 255, .6);
}

.rno-3-hero-item-btns .rno-btn-weak:hover {
  color: #fff;
}

.rno-3-hero-links {
  display: inline-block;
  line-height: 40px;
    
}

.rno-3-hero-item-ctn > .rno-3-hero-links {
  margin-top: 8px;
}

.rno-3-hero-links a {
  font-size: 14px;
  color: #fff;
}

.rno-3-hero-item-btns .rno-3-hero-links a {
  margin-left: 10px;
}

/* 标题有链接，去掉上边距 */
.rno-3-hero-links ~ .rno-3-hero-item-title {
  margin-top: 0;
}

.rno-3-hero-item-video-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .6;
}

.rno-3-hero-item-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* 移动端 */
@media screen and (max-width: 768px) {
  .rno-3-hero-item-inner {
    padding: 32px 0 0;

  }

  .rno-3-hero-item-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .rno-3-hero-item-desc {
    font-size: 14px;
    -webkit-line-clamp: 2;
    line-height: 24px;
    height: 48px;
    max-height: 48px;
  }

  .rno-3-hero {
    height: auto;
  }

  .rno-3-hero-item {
    padding: 0 16px;
    height: 248px;
  }

  .rno-3-hero-embellish {
    display: none;
  }

  .rno-3-hero-item-bg,
  .rno-3-hero-main-body {
    display: none;
  }

  /* 通用banner */
  .rno-3-hero-group-index .rno-3-hero-item-title {
    text-align: center;
  }

  .rno-3-hero-group-index .rno-3-hero-links {
    display: none;
  }

  /* 二级页面移动端 */
  .rno-3-hero-links {
    line-height: 20px;
    margin-bottom: 12px;
  }

  .rno-3-hero-links a {
    font-size: 14px;
    line-height: 20px;
  }

  /* 按钮栅格 */
  .rno-3-hero-item-btns {
    margin-top: 24px;
  }

  .rno-3-hero-item-btns.rno-3-hero-item-list-1 .rno-btn {
    width: 100%;
  }

  .rno-3-hero-item-btns.rno-3-hero-item-list-2 .rno-btn {
    margin-right: 12px;
    width: calc((100% - 12px) / 2);
  }

  .rno-3-hero-item-btns.rno-3-hero-item-list-2 .rno-btn:nth-child(2) {
    margin-right: 0;
  }

  /* 移动端下隐藏第3个按钮 */
  .rno-3-hero-item-btns .rno-btn:nth-child(3) {
    display: none;
  }
}

/* 追加的样式 */
.rno-3-hero-item-link {
  color: #fff;
  line-height: 26px;
  font-size: 14px;
  margin-top: 16px;
}

.rno-3-hero-item-link a {
  color: #00a4ff;
}

.rno-3-hero-item-link a:hover {
  text-decoration: underline;
}

/* tab切换按钮 */
.rno-2-hero-tab-wrap {
  position: absolute;
  left: 0;
  top: 358px;
  width: 100%;
}

.rno-2-hero-tab {
  width: 1200px;
  padding: 0 7px;
  margin: 0 auto;
}

.rno-2-hero-tab li {
  margin: 0 3px;
  display: inline-block
}

.rno-2-hero-tab li a {
  display: inline-block;
  padding: 10px 0;
  width: auto;
  height: auto;
  font-size: 0;
  outline: none;
}

.rno-2-hero-tab li a i {
  display: inline-block;
  width: 30px;
  height: 3px;
  background: rgba(255,255,255,.16);
  border-radius: 2px;
  text-align: left;
  overflow: hidden;
}

.rno-2-hero-tab li a i b {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #fff;
}


.rno-2-hero-tab .rno-2-hero-tab-selected a i {
  background-color: #fff;
  opacity: 1;
  transition: all .2s linear;
}



@media screen and (max-width: 768px) {
  .rno-3-hero-item-title {
    margin-top: 0;
    text-align: center;
  }

  .rno-3-hero-item-link {
    display: none;
  }

  .rno-2-hero-tab-wrap {
    top: 206px;
    width: 100%;
    text-align: center;
  }

  .rno-2-hero-tab {
    width: 100%;
  }

}





/* 内页banner个性化 s */
@media screen and (min-width: 769px) {
  .rno-3-hero-group-detail .rno-3-hero {
    height: 380px;
  }

  .rno-3-hero-group-detail .rno-3-hero-item-inner {
    padding: 124px 10px 0;
  }
}

/* 内页banner个性化 e */