@charset "utf-8";

.rno-btn {
  height: 36px;
  min-width: 88px;
  padding: 0 16px;
  background-color: #0052D9;
  border: 1px solid transparent;
  color: #fff;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none
}

.rno-btn-text {
  color: inherit;
  -webkit-font-smoothing: antialiased;
}

.rno-btn:hover {
  text-decoration: none;
  background-color: #2670E8;
  transition: all 0.3s ease-in-out;
}

.rno-btn-pay {
  background-color: #ff7200
}

.rno-btn-pay:hover {
  background-color: #f06c00
}

.rno-btn-hole {
  border-color: #0052D9;
  background-color: transparent;
  color: #00a4ff
}

.rno-btn-hole:hover {
  background-color: #fff;
  border-color: #00a4ff;
  color: #00a4ff
}

.rno-btn-white-hole {
  border-color: #dcdee6;
  background: 0 0;
  color: #fff
}

.rno-btn-white-hole:hover {
  background-color: #fff;
  color: #000
}

.rno-btn-weak {
  background: 0 0;
  border-color: #dcdee6;
  color: #666
}

.rno-btn-weak:hover {
  background: #fff;
  border-color: #999;
  color: #000
}

.rno-btn-weak:hover .rno-btn-text {
  color: #000;
}

.rno-btn-link {
  min-width: 0;
  border: none;
  background-color: transparent;
  color: #333
}

.rno-btn-link:hover {
  color: #00a4ff;
  border: none;
  background-color: transparent
}

.rno-btn-disabled,
.rno-btn-disabled:hover {
  background-color: #ddd;
  border-color: #ddd;
  color: #999;
  cursor: default
}

.rno-btn-l {
  height: 40px;
  line-height: 38px
}

.rno-btn-s {
  height: 32px;
  line-height: 30px
}

.rno-btn-xs {
  height: 28px;
  line-height: 26px;
  min-width: 80px;
  padding: 0 10px;
  font-size: 12px
}

.rno-btn-full-width {
  min-width: 0;
  display: block;
  width: 100%
}

.rno-btn-auto-width {
  min-width: 0
}

.rno-btn .rno-loading {
  display: none
}

.rno-btn .rno-loading-dot {
  background-color: #fff;
  vertical-align: middle
}

.rno-btn-loading {
  cursor: default
}

.rno-btn-loading .rno-btn-text {
  display: none
}

.rno-btn-loading .rno-loading {
  display: block
}

.rno-btn-loading:hover {
  background-color: #0052D9
}

.rno-btn-pay.rno-btn-loading:hover {
  background-color: #ff7200
}

.rno-btn-hole .rno-loading-dot {
  background-color: #00a4ff
}

.rno-btn-hole.rno-btn-loading:hover,
.rno-btn-white-hole.rno-btn-loading:hover {
  background-color: transparent
}

.rno-btn-weak .rno-loading-dot {
  background-color: #ddd
}

.rno-btn-weak.rno-btn-loading:hover {
  background-color: transparent;
  border-color: #ddd
}

.rno-btn-link .rno-loading-dot {
  background-color: #ddd
}

.rno-btn-link.rno-btn-loading:hover {
  background-color: transparent
}

.rno-btn-disabled .rno-loading-dot {
  background-color: #999
}

.rno-btn-disabled.rno-btn-loading:hover {
  background-color: #ddd
}

.rno-upload-btn {
  position: relative;
  overflow: hidden
}

.rno-upload-btn-file {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 200px;
  -webkit-font-smoothing: antialiased;
}

.rno-upload-btn.rno-btn-disabled .rno-upload-btn-file {
  display: none
}