@charset "UTF-8";

.rno-tabs-bar {
  text-align: center;
}

.rno-tabs-item {
  display: inline-block;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin: 0 10px
}

.rno-tabs-item-con {
  display: block;
  padding: 0 10px;
  text-decoration: none;
  font-size: 18px;
  line-height: 50px;
  color: #000
}

.rno-tabs-list {
  font-size: 0;
  border-bottom: #e5e5e5 1px solid;
  position: relative;
  list-style: none
}

.rno-tabs-item-con:hover {
  color: #00a4ff
}

.rno-tabs-active .rno-tabs-item-con {
  font-weight: 500;
  color: #00a4ff
}

.rno-tabs-disabled {
  cursor: default
}

.rno-tabs-disabled .rno-tabs-item-con,
.rno-tabs-disabled .rno-tabs-item-con:hover {
  color: #ccc;
  cursor: not-allowed;
  font-weight: 400
}

.rno-tabs-active .rno-tabs-item-con:after {
  display: inline-block;
  content: '';
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  background: #00a4ff;
  z-index: 1
}

.rno-tabs-content {
  padding: 40px 0 0
}

@media screen and (max-width: 768px) {
  .rno-tabs-content {
    padding: 0
  }
}

.rno-tabs-vertical {
  display: table;
  width: 100%
}

.rno-tabs-vertical .rno-tabs-bar {
  display: table-cell;
  width: 15%;
  position: relative;
  vertical-align: top
}

.rno-tabs-vertical .rno-tabs-bar:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #e5e5e5
}

.rno-tabs-vertical .rno-tabs-panel {
  display: table-cell;
  width: 85%
}

.rno-tabs-vertical .rno-tabs-item {
  display: block;
  border-bottom: 0 none;
  margin-left: 0;
  margin-right: 0
}

.rno-tabs-vertical .rno-tabs-item-con {
  display: block;
  padding: 5px 0;
  text-decoration: none
}

.rno-tabs-vertical .rno-tabs-active .rno-tabs-item-con:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  height: 100% !important;
  background-color: #00a4ff;
  left: auto;
  bottom: 0;
  z-index: 1
}

.rno-tabs-vertical .rno-tabs-list {
  border-bottom: 0 none
}

.rno-tabs-vertical .rno-tabs-content {
  padding: 0 0 0 10px
}

@media screen and (max-width: 768px) {
  .rno-tabs-item-con {
    font-size: 16px;
    line-height: 26px;
    padding: 0 10px 4px;
  }

  .rno-tabs-item {
    margin: 0 8px;
  }




}