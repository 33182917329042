@charset "utf-8";

/* 金融行业解决方案 */
.rno-12-media-panel {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  text-decoration: none;
  position: relative;

  /* top: 0; */
  transition: all .2s linear;
  box-shadow: 0 2px 4px rgba(3,27,78,.06);
  /* border: 1px solid #e5e8ed; */
}

@media screen and (min-width: 769px) {
  .rno-12-media-panel:hover {
    box-shadow: 0 4px 8px rgba(3,27,78,.12);

    /* top: -3px; */

    /* cursor: pointer; */
  }

  /* .rno-12-media-panel:hover .rno-12-media-panel-title{
        color: #00A4FF;
    } */
}

/* 头图 */
.rno-12-media-panel-hd {
  height: 100px;
}

/* 一行两列的时候图片高度120px */
.rno-lattice-cols-2 .rno-12-media-panel-hd {
  height: 120px;
}

.rno-12-media-panel-view {
  display: block;
  width: 100%;
  height: 100%;
}

.rno-12-media-panel-view-m {
  display: none;
}

/* 内容 */
.rno-12-media-panel-bd {
  padding: 20px;
}

.rno-12-media-panel-title {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 28px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rno-12-media-panel-cont{

}

.rno-12-media-panel-desc {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

.rno-12-media-panel-links {
  margin-top: 8px;    

  /* padding-bottom: 8px; */
}

/* pc下两列的情况 不需要占满全部宽度 */
@media screen and (min-width: 769px) {
  .rno-12-media-panel-links.rno-list-2 {
    width: 60%;
  }

  .rno-12-media-panel-links.rno-list-2 .rno-12-media-panel-text {
    width: 48%;
    display: inline-block;
  }
}

.rno-12-media-panel-text {
  /* display: inline-block; */
  display: block;
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* width: 48%; */
  margin-bottom: 8px;
}

.rno-12-media-panel-text a {
  color: #333;
}

.rno-12-media-panel-text a:hover {
  color: #0052D9;
}

/* .rno-12-media-panel-text a::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #666;
    display: inline-block;
    vertical-align: 4px;
    margin-right: 4px;
    border-radius: 50%;
} */

/* 底部 */
.rno-12-media-panel-ft {
  border-top: 1px solid #e5e5e5;
  padding-top: 12px;
  margin-top: 12px;
}

.rno-12-media-panel-ft .rno-link {
  font-size: 14px;
  color: #0052D9;
  line-height: 24px;
}


/* 清除底部边距 s */

/* @media screen and (min-width: 769px) {
    .rno-industry-section .rno-lattice-card > .rno-lattice-cell{
        margin-bottom: 0;
    }
} */

/* 清除底部边距 e */


@media screen and (max-width: 768px) {
  .rno-12-media-panel-hd {
    height: 100px;
  }

  /* 图片区分pc和移动端显示 */
  .rno-12-media-panel-view {
    display: none;
  }

  .rno-12-media-panel-view-m {
    display: block;
    width: 100%;
    height: 100%;
  }

  /* .rno-industry-section .rno-lattice-card > .rno-lattice-cell:last-child{
        margin-bottom: 0;
    } */

}


/* 追加样式 */
.rno-12-media-panel-item {
  margin-bottom: 32px;
}

.rno-12-media-panel-cont .rno-12-media-panel-item:last-child {
  margin-bottom: 0;
}

.rno-12-media-panel-hd {
  height: 160px;
  position: relative;
  background-color: #F5F7FA;
}

.rno-12-media-panel-hd-title {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 20px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  color: #fff;
  font-size: 18px;
  background: rgba(0,0,0,.3);
  display: box;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 44px;
  overflow: hidden;
}


.rno-12-media-panel-item .rno-link {
  margin-top: 6px;
  display: block;
}

.rno-12-media-panel-subtitle {
  display: block;

  position: relative;
}

.rno-12-media-panel-subtitle span {
  font-size: 14px;
  color: #000;
  line-height: 22px;
  background-color: #fff;
  position: relative;
  z-index: 5;
  padding-right: 20px;
  font-weight: 500;
}

.rno-12-media-panel-subtitle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
  width: 100%;
  height: 1px;
  background-color: #e2e6ed;
}
