@charset "UTF-8";

/* 创新解决方案 */
.rno-tabs-simple {
  font-size: 0;
  position: relative;

  /* padding-top: 40px; */
}

.rno-tabs-simple-tabs {
  position: absolute;
  left: 0;
  top: -28px;
}

.rno-tabs-simple-tabs li {
  display: inline-block;
  text-align: center;
}

.rno-tabs-simple-tabs li a {
  font-size: 16px;
  color: #333;
  line-height: 26px;
  padding: 0 20px;
}

.rno-tabs-simple-tabs li:last-child {
  margin-right: 0;
}

.rno-tabs-simple-tabs li.active a {
  color: #00a4ff;
}

/* 子菜单 */
.rno-tabs-simple-panel {
  display: none;
}

.rno-tabs-simple-img {
  display: inline-block;
  vertical-align: middle;
  max-width: 68%;
  height: auto;
  width: 68%; 
}

.rno-tabs-simple-img-view {
  display: block;
  max-width: 100%;
}

.rno-tabs-simple-box {
  width: 28%;
  float: right;
}

.rno-tabs-simple-cont {
  padding-bottom: 20px;
}

.rno-tabs-simple-btn-wrap .rno-btn {
  min-width: 140px;
  margin-top: 28px;
}

/* 子标题 */
.rno-tabs-simple-cont-title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #000;
  font-weight: 500;
}

/* 图片 */
.rno-tabs-simple-cont-banner {
  margin-bottom: 20px;
}

.rno-tabs-simple-cont-banner img {
  width: 100%;
  display: block;
}

/* 子标题 */
.rno-tabs-simple-tab-title {
  font-size: 16px;
  color: #000;
  line-height: 26px;
  margin-bottom: 20px;    
}

/* 正文标题 */
.rno-tabs-simple-title {
  font-size: 16px;
  color: #000;
  line-height: 26px;
  margin-bottom: 20px;
}

.rno-tabs-simple-list li {
  font-size: 14px;
  color: #666;
  letter-spacing: 0;
  line-height: 24px;  
  padding-left: 8px;
  margin-bottom: 8px;
  position: relative;
}

.rno-tabs-simple-list li::before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #666;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.rno-tabs-simple-list-title {
  display: block;
  font-size: 14px;
  color: #000;
  line-height: 24px;
  margin-bottom: 8px;    
}

.rno-tabs-m-title-wrap {
  display: none;
}

.rno-tabs-simple-panel.active {
  display: block;
}

.rno-tabs-simple-body {
  display: none;
}

.rno-tabs-simple-panel.active .rno-tabs-simple-body {
  display: table;
}

.rno-tabs-simple-text {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-bottom: 8px;
  position: relative;
}

/* 移动端 */
@media screen and (max-width: 768px) {
  .rno-tabs-simple-body {
    padding-left: 16px;
    padding-right: 16px;
  }

  .rno-tabs-simple {
    padding-top: 0;
    border-top: 1px solid #e5e5e5;
    margin-left: -15px;
    margin-right: -15px;
  }

  .rno-tabs-simple-tabs,
  .rno-tabs-bar {
    display: none;
  }


  /* 左侧内容 */
  .rno-tabs-simple-img,
  .rno-tabs-simple-img-view {
    width: 100%;
    max-width: 100%;
  }

  .rno-tabs-simple-img {
    margin-bottom: 20px;
  }

  /* 右侧内容 */
  .rno-tabs-simple-box {
    float: none;
    width: 100%;
  }

  .rno-tabs-simple-list {
    margin-bottom: 20px;
  }

  .rno-tabs-simple-btn-wrap .rno-btn {
    margin-top: 0;
    width: 100%;
    line-height: 40px;
    height: 40px;
  }

  /* 移动端卡片内标题 */
  .rno-innovate-section .rno-tabs-m-title-sub {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  /* 去掉多余间距 */
  .rno-section.rno-innovate-section {
    padding-bottom: 0;
  }

  .rno-tabs-simple-cont .rno-lattice-cell:last-child {
    margin-bottom: 0;
  }


}