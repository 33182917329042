@charset "utf-8";

/* 顶部tab切换 */
.rno-header-subnav {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e5e8ed;
}

.rno-header-subnav-tabs {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 0;
  display: flex;
  justify-content: center;
}

.rno-header-subnav-tab {
  display: inline-block;
  font-size: 16px;
  line-height: 60px;
  color: #000;
  padding: 0 26px;
  position: relative;
}

.rno-header-subnav-tab:last-child {
  margin-right: 0;
}

@media screen and (min-width: 769px) {
  .rno-header-subnav-tab:hover {
    color: #0052D9;
  }
}

.rno-header-subnav-tab.active {
  color: #0052D9;
  font-weight: 500;
}

.rno-header-subnav-tab.active::before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #0052D9;
  position: absolute;
  left: 0;
  bottom: -1px;
}

@media screen and (max-width: 768px) {
  .rno-header-subnav-tabs-wrap {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .rno-header-subnav-tabs {
    font-size: 0;
    white-space: nowrap;
  }

  .rno-header-subnav-tab {
    margin-right: 0;
    line-height: 44px;
    padding: 0 16px;
  }

  .qualification-table-info-body td i {
    display: none;
  }
}