@charset "utf-8";

/* rno组件重置 */
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

/* 移动端底色 */
@media screen and (max-width: 768px) {
  .partners-container {
    background-color: #f5f7fa;
  }
}

/* 前端重置边距 */
.no-margin {
  margin: 0!important;
}