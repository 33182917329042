[class*=rno-i-],
[class^=rno-i-] {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px
}

[class*=rno-i-s-],
[class^=rno-i-s-] {
  width: 12px;
  height: 12px
}

.rno-i-s-arrow-right-blue {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT7nlLvmnb88L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i55S75p2/IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDEuMDAwMDAwKSIgZmlsbD0iIzAwQTRGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IuiTneiJsuWIh+aNoiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC4wMDAwMDAsIDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iQ29tYmluZWQtU2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCA1LjAwMDAwMCkgc2NhbGUoLTEsIDEpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0zLjUwMDAwMCwgLTUuMDAwMDAwKSAiIHBvaW50cz0iNC4xOTk4MTY4IDQuOTk5NzEyODIgOS44OTllLTA1IDguNzQ5MzQ4MTggMS4zOTk4ODEyMSAxMCA3IDUuMDAwMDQ0MTggNS42MDAyMTc4IDMuNzQ5MzkyMzYgMS4zOTk3ODIyMSAwIDAgMS4yNTA2NTE4MiI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjQiIHdpZHRoPSI5IiBoZWlnaHQ9IjIiPjwvcmVjdD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
}

.rno-i-s-2-arrow-bottom {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT7nlLvmnb88L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i55S75p2/IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiM3Qzg0OEUiIGZpbGwtcnVsZT0ibm9uemVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSBzY2FsZSgxLCAtMSkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtNi4wMDAwMDAsIC02LjAwMDAwMCkgIiBwb2ludHM9IjcuMDkzNDA0NzQgNS45OTk2OTQ1NCAyLjAwMDEwMzggMTAuNjcwMTg1MyAzLjQ1MzA3OTY1IDEyIDEwIDYuMDAwMDQ3MzcgOC41NDcwMjQxNSA0LjY3MDIzMjY0IDMuNDUyOTc1ODUgMCAyIDEuMzI5ODE0NzMiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+);
}

.rno-i-s-2-arrow-top-blue {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT7nlLvmnb/lpIfku708L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i55S75p2/5aSH5Lu9IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiMwMEE0RkYiIGZpbGwtcnVsZT0ibm9uemVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC02LjAwMDAwMCwgLTYuMDAwMDAwKSAiIHBvaW50cz0iNy4wOTM0MDQ3NCA1Ljk5OTY5NDU0IDIuMDAwMTAzOCAxMC42NzAxODUzIDMuNDUzMDc5NjUgMTIgMTAgNi4wMDAwNDczNyA4LjU0NzAyNDE1IDQuNjcwMjMyNjQgMy40NTI5NzU4NSAwIDIgMS4zMjk4MTQ3MyI+PC9wb2x5Z29uPgogICAgPC9nPgo8L3N2Zz4=);
}

.rno-i-s-link-blue {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT7lpJbpk748L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0i5aSW6ZO+IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHg9IjAiIHk9IjAiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiI+PC9yZWN0PgogICAgICAgIDxnIGlkPSLpk77mjqXngbDoibIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIGZpbGw9IiMwMEE0RkYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00Ljk5OTk1LC0wLjAwMDIgTDcuMTQ1OTMsMi4xNDY2MyBMNC4zNzc5Niw0LjkxNDQxIEw1LjA4NTk1LDUuNjIwMzUgTDcuODUyOTIsMi44NTM1NyBMOS45OTg5LDQuOTk5NCBMOS45OTg5LC0wLjAwMDIgTDQuOTk5OTUsLTAuMDAwMiBaIE0wLC0wLjAwMDIgTDAsMTAgTDkuOTk4OSwxMCBMOS45OTg5LDYuOTk5MjQgTDguOTk5OTEsNi45OTkyNCBMOC45OTk5MSw5LjAwMDA4IEwwLjk5ODk5LDkuMDAwMDggTDAuOTk4OTksMS4wMDA3MiBMMi45OTk5NywxLjAwMDcyIEwyLjk5OTk3LC0wLjAwMDIgTDAsLTAuMDAwMiBaIiBpZD0iUGFnZS0xIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
}

.rno-i-download {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZSBDb3B5IDI1PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IuaegeWFieaOqOmAgSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTcwNC4wMDAwMDAsIC0yMjA5LjAwMDAwMCkiIGZpbGw9IiM3Qzg0OEUiPgogICAgICAgICAgICA8cGF0aCBkPSJNNzEzLDIyMTUgTDcxNiwyMjE1IEw3MTIsMjIxOSBMNzA4LDIyMTUgTDcxMSwyMjE1IEw3MTEsMjIwOSBMNzEzLDIyMDkgTDcxMywyMjE1IFogTTcyMCwyMjIzIEw3MjAsMjIyNSBMNzA2LDIyMjUgTDcwNCwyMjI1IEw3MDQsMjIxNSBMNzA2LDIyMTUgTDcwNiwyMjIzIEw3MTgsMjIyMyBMNzE4LDIyMTUgTDcyMCwyMjE1IEw3MjAsMjIyMyBaIiBpZD0iQ29tYmluZWQtU2hhcGUtQ29weS0yNSI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
}

.rno-i-file {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTExLDIuMjcyMjAxIEwxMSw0LjcwNTg4MjM1IEwxMy41ODU3ODY0LDQuNzA1ODgyMzUgTDExLDIuMjcyMjAxIFogTTE2LDQuNzA1ODgyMzUgTDE2LDE2IEwwLDE2IEwwLDAgTDExLDAgTDExLjQxNDIxMzYsMS4yMDc5MjI2NWUtMTMgTDE2LDQuMzE2MDM0MjkgTDE2LDQuNzA1ODgyMzUgWiBNOSwxLjg4MjM1Mjk0IEwyLDEuODgyMzUyOTQgTDIsMTQuMTE3NjQ3MSBMMTQsMTQuMTE3NjQ3MSBMMTQsNi41ODgyMzUyOSBMOSw2LjU4ODIzNTI5IEw5LDEuODgyMzUyOTQgWiBNNCw4IEw5LDggTDksMTAgTDQsMTAgTDQsOCBaIE00LDExIEwxMiwxMSBMMTIsMTMgTDQsMTMgTDQsMTEgWiIgaWQ9IkNvbWJpbmVkLVNoYXBlIiBmaWxsPSIjN0M4NDhFIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+);
}

.rno-collapse-item {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e5e5e5
}

.rno-collapse-item-hd {
  position: relative;
  padding: 0 40px 0 20px;
  height: 50px;
  background-color: #fff;
  cursor: pointer
}

.rno-collapse-item-title {
  padding: 10px 0;
  font-size: 16px;
  line-height: 30px;
  color: #333;
  font-weight: 400
}

.rno-collapse-item-caret {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -6px
}

.rno-collapse-item-bd {
  display: none;
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  font-size: 14px;
  line-height: 28px;
  color: #333
}

.rno-collapse-item-active .rno-collapse-item-bd {
  display: block
}

.rno-collapse-item-active .rno-collapse-item-title {
  color: #0052D9
}

.rno-collapse-list {
  border-top: 1px solid #e5e5e5
}

.rno-collapse-list-skin-question {
  border-top: none
}

.rno-collapse-list-skin-question .rno-collapse-item {
  background: 0 0;
  border-bottom-color: #dbe1e6
}

.rno-collapse-list-skin-question .rno-collapse-item-hd {
  height: auto;
  min-height: 60px;
  background-color: transparent;
  padding-left: 0
}

.rno-collapse-list-skin-question .rno-collapse-item-title {
  padding: 18px 0;
  line-height: 24px
}

.rno-collapse-list-skin-question .rno-collapse-item-caret {
  width: 21px;
  height: 12px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjFweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMjEgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5pbmRpY2F0aW9uIGNvcHkgMjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSLop4bop4kiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSLmjqjlub/lpZblirEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDc5LjAwMDAwMCwgLTM4NjguMDAwMDAwKSIgZmlsbD0iI0RCRTFFNiI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzY5LjAwMDAwMCwgMzUyNy4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMTE2LjEyMDgyLDMzNi44OTIwNTMgTDExMjYuMTA3OTUsMzQ2Ljg3OTE3NiBMMTExNS44NzkxOCwzNTcuMTA3OTQ3IEwxMTE0LjEwNzk1LDM1NS4zMzYwNTMgTDExMjIuNTY1NDksMzQ2Ljg3OTE3NiBMMTExNC4zNDg5NSwzMzguNjYzMDUzIEwxMTE2LjEyMDgyLDMzNi44OTIwNTMgWiIgaWQ9ImluZGljYXRpb24tY29weS0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTIwLjEwNzk0NywgMzQ3LjAwMDAwMCkgc2NhbGUoLTEsIC0xKSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMTIwLjEwNzk0NywgLTM0Ny4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
  background-size: 100% auto;
}

.rno-collapse-list-skin-question .rno-collapse-item-bd {
  border-top-color: #dbe1e6;
  padding: 20px 15px;
  line-height: 22px;
  color: #666
}

.rno-collapse-list-skin-question .rno-collapse-item-active .rno-collapse-item-caret {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjFweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMjEgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5pbmRpY2F0aW9uIGNvcHkgMzwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSLop4bop4kiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSLmjqjlub/lpZblirEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDc5LjAwMDAwMCwgLTM2MTMuMDAwMDAwKSIgZmlsbD0iI0RCRTFFNiI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzY5LjAwMDAwMCwgMzUyNy4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMTE2LjEyMDgyLDgxLjg5MjA1MzEgTDExMjYuMTA3OTUsOTEuODc5MTc1OSBMMTExNS44NzkxOCwxMDIuMTA3OTQ3IEwxMTE0LjEwNzk1LDEwMC4zMzYwNTMgTDExMjIuNTY1NDksOTEuODc5MTc1OSBMMTExNC4zNDg5NSw4My42NjMwNTMxIEwxMTE2LjEyMDgyLDgxLjg5MjA1MzEgWiIgaWQ9ImluZGljYXRpb24tY29weS0zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTIwLjEwNzk0NywgOTIuMDAwMDAwKSBzY2FsZSgtMSwgMSkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEyMC4xMDc5NDcsIC05Mi4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
}

.rno-collapse-list-skin-question .rno-collapse-item-active .rno-collapse-item-title {
  color: #333
}


@media screen and (max-width: 768px) {
  .rno-collapse-list-skin-question .rno-collapse-item {
    border-bottom-color: #ddd
  }

  .rno-collapse-list-skin-question .rno-collapse-item-hd {
    min-height: 48px
  }

  .rno-collapse-list-skin-question .rno-collapse-item-title {
    padding-top: 14px;
    padding-bottom: 14px;
    line-height: 20px;
    font-size: 14px
  }

  .rno-collapse-list-skin-question .rno-collapse-item-caret {
    right: 13px;
    margin-top: -4px;
    width: 13px;
    height: 8px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMyA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+Q29tYmluZWQgU2hhcGUgQ29weSA2PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IuinhuiniSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IuaOqOW5v+WlluWKsV/np7vliqjnq68iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMzQuMDAwMDAwLCAtMzQ1MC4wMDAwMDApIiBmaWxsPSIjRDhEOEQ4IiBzdHJva2U9IiM5Nzk3OTciPgogICAgICAgICAgICA8cGF0aCBkPSJNMzM1LjM4MDg5MSwzNDUwLjcxMjQ3IEwzMzQuNzAxODYzLDM0NTEuNDAxNzYgTDM0MC41LDM0NTcuMjg3NTMgTDM0Ni4yOTgxMzcsMzQ1MS40MDE3NiBMMzQ1LjYxOTEwOSwzNDUwLjcxMjQ3IEwzNDAuNSwzNDU1LjkwODk1IEwzMzUuMzgwODkxLDM0NTAuNzEyNDcgWiIgaWQ9IkNvbWJpbmVkLVNoYXBlLUNvcHktNiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .rno-collapse-list-skin-question .rno-collapse-item-bd {
    border-top-color: #ddd;
    padding: 10px 0;
    line-height: 24px
  }

  .rno-collapse-list-skin-question .rno-collapse-item-active .rno-collapse-item-caret {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMyA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+Q29tYmluZWQgU2hhcGUgQ29weSA5PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IuinhuiniSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IuaOqOW5v+WlluWKsV/np7vliqjnq68iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMzQuMDAwMDAwLCAtMzMwOC4wMDAwMDApIiBmaWxsPSIjRDhEOEQ4IiBzdHJva2U9IiM5Nzk3OTciPgogICAgICAgICAgICA8cGF0aCBkPSJNMzQ1LjYxOTEwOSwzMzE1LjI4NzUzIEwzNDYuMjk4MTM3LDMzMTQuNTk4MjQgTDM0MC41LDMzMDguNzEyNDcgTDMzNC43MDE4NjMsMzMxNC41OTgyNCBMMzM1LjM4MDg5MSwzMzE1LjI4NzUzIEwzNDAuNSwzMzEwLjA5MTA1IEwzNDUuNjE5MTA5LDMzMTUuMjg3NTMgWiIgaWQ9IkNvbWJpbmVkLVNoYXBlLUNvcHktOSI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .rno-collapse-item .rno-collapse-item-bd {
    padding-bottom: 0;
  }

  /* 清除折叠容器下边距 */
  .rno-section.rno-scene-tabs-section {
    padding-bottom: 0;
  }
}



/* 追加的样式 */
@media screen and (max-width: 768px) {
  section.college-wrap {
    padding-bottom: 0;
  }

  .college-wrap .rno-section-bd {
    display: none;
  }

  .rno-scene-main-list li {
    margin-bottom: 12px;
  }

  .rno-scene-main-list li a {
    color: #0052D9;
  }







}