@charset "utf-8";

/* 滚动条公共样式 s */
.rno-panel-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px
}

.rno-panel-scroll::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 20px
}

.rno-panel-scroll::-webkit-scrollbar-track {
  border-radius: 20px
}

/* 滚动条公共样式 e */