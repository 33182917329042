@charset "utf-8";

/* vip专属深色 */
@media screen and (min-width: 769px) {
  .bg-color-dark {
    background: #1c2437;
  }

  .bg-color-dark .rno-section-tit,
  .bg-color-dark .rno-16-media-panel-title,
  .bg-color-dark .rno-1-media-panel-title {
    color: #fff;
  }

  .bg-color-dark .rno-16-media-panel-desc,
  .bg-color-dark .rno-1-media-panel-desc {
    color: #999;
  }

  .bg-color-dark .rno-lattice-line > .rno-lattice-cell:after {
    background-color: #293142;
  }

}



/* vip客户服务模块 s */
@media screen and (min-width: 769px) {
  .bg-color-dark .rno-16-media-panel-icon {
    display: none;
  }

  .bg-color-dark .rno-16-media-panel-icon-dark {
    display: block;
  }

}

@media screen and (max-width: 768px) {
  .bg-color-dark .rno-16-media-panel-icon {
    display: block;
  }

  .bg-color-dark .rno-16-media-panel-icon-dark {
    display: none;
  }
}

/* vip客户服务模块 e */



@media screen and (min-width: 769px) {
  .bg-color-dark .rno-1-media-panel-icon {
    display: none;
  }

  .bg-color-dark .rno-1-media-panel-icon-dark {
    display: block;
  }

}

@media screen and (max-width: 768px) {
  .bg-color-dark .rno-1-media-panel-icon {
    display: block;
  }

  .bg-color-dark .rno-1-media-panel-icon-dark {
    display: none;
  }
}