@charset "utf-8";

/* 开发者资源 */
.rno-17-media-panel {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  text-decoration: none;
  position: relative;

  /* top: 0; */
  transition: all .2s linear;
  box-shadow: 0 2px 4px rgba(3,27,78,.06);
  border: 1px solid #e5e8ed;
}

@media screen and (min-width: 769px) {
  .rno-17-media-panel:hover {
    box-shadow: 0 4px 8px rgba(3,27,78,.12);

    /* top: -3px; */
  }

  .rno-17-media-panel:hover .rno-17-media-panel-title {
    transition: all .2s linear;
    color: #0052d9;
  }
}

/* 头部区域 */
.rno-17-media-panel-hd{

}

.rno-17-media-panel-title-wrap {
  position: relative;
  padding: 20px 56px 20px 20px;
  overflow: hidden;
  z-index: 5;
}

.rno-17-media-panel-title {
  font-size: 18px;
  line-height: 28px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rno-17-media-panel-desc {
  font-size: 14px;
  line-height: 24px;
  color: #666;
  margin-top: 4px;
}

.rno-17-media-panel-hd-object {
  position: absolute;
  right: 0;
  top: 0;
  width: 580px;
  height: 136px;
  z-index: 1;
}

.rno-17-media-panel-icon {
  width: 100%;
  height: 100%;
}

.icon-bg-1 {
  background-image: url(https://cdn.panshi.qq.com/public/icon-bg-1.png);
  background-size: cover;
}

.icon-bg-2 {
  background-image: url(https://cdn.panshi.qq.com/public/icon-bg-2.png);
  background-size: cover;
}

.icon-bg-3 {
  background-image: url(https://cdn.panshi.qq.com/public/icon-bg-3.png);
  background-size: cover;
}

.icon-bg-4 {
  background-image: url(https://cdn.panshi.qq.com/public/icon-bg-4.png);
  background-size: cover;
}




/* 列表内容 */
.rno-17-media-panel-bd {
  border-top: 1px solid #e5e5e5;   
}

.rno-17-media-panel-list-wrap {
  padding: 20px 20px 12px;
}

.rno-17-media-panel-list{
    
}

.rno-17-media-panel-list li {
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  height: 24px;
}

.rno-17-media-panel-list li a {
  font-size: 14px;
  color: #333;
}

.rno-17-media-panel-list li a:hover {
  color: #00a4ff;
}

.rno-17-media-panel-links {
  margin-top: 12px;
  font-size: 0;
}

.rno-17-media-panel-links > a {
  color: #00a4ff;
  font-size: 14px;
  margin-left: 32px;
  padding-left: 32px;
  position: relative;
}

.rno-17-media-panel-links > a:first-child {
  margin-left: 0;
  padding-left: 0;
}

.rno-17-media-panel-links > a::before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 1px;
  height: 16px;
  background-color: #ddd;
  display: inline-block;
  vertical-align: middle;
}

.rno-17-media-panel-links > a:first-child::before {
  display: none;
}

.rno-icon-link,
.rno-icon-down {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: -3px;
  background-size: cover;
  margin-left: 4px;
}

.rno-icon-link {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTgiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PC9yZWN0PgogICAgICAgICAgICA8cGF0aCBkPSJNMTIuMjQyNjQwNyw1LjI0MjY0MDY5IEw3LjcwNzEwNjc4LDkuNzc4MTc0NTkgTDYuMjkyODkzMjIsOC4zNjM5NjEwMyBMMTAuODI4NDI3MSwzLjgyODQyNzEyIEw4LDEgTDE1LDEgTDE1LDggTDEyLjI0MjY0MDcsNS4yNDI2NDA2OSBaIE0xMywxMCBMMTUsMTAgTDE1LDE1IEwxLDE1IEwxLDEgTDYsMSBMNiwzIEwzLDMgTDMsMTMgTDEzLDEzIEwxMywxMCBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiMwMEE0RkYiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
}

.rno-icon-down {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDY0ICg5MzUzNykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+57yW57uEPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9Iue8lue7hCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxyZWN0IGlkPSLnn6nlvaIiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PC9yZWN0PgogICAgICAgICAgICA8cGF0aCBkPSJNMTQsMTMgTDE0LDE1IEwyLDE1IEwyLDEzIEwxNCwxMyBaIE05LDEgTDguOTk5LDggTDEwLjgyODQyNzEsNi4xNzE1NzI4OCBMMTIuMjQyNjQwNyw3LjU4NTc4NjQ0IEw4LDExLjgyODQyNzEgTDgsMTEuODI4NDI3MSBMMy43NTczNTkzMSw3LjU4NTc4NjQ0IEw1LjE3MTU3Mjg4LDYuMTcxNTcyODggTDYuOTk5LDcuOTk5IEw3LDEgTDksMSBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiMwMEE0RkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
}


/* 移动端 */
@media screen and (max-width: 768px) {
  .rno-17-media-panel-list-wrap {
    min-height: inherit;
  }

  .rno-17-media-panel-hd-object {
    width: 100%;
    height: 100%;
  }

  .rno-17-media-panel-desc {
    width: 80%;
    height: 48px;
    margin-top: 0;
  }

  .rno-17-media-panel-icon {
    width: 480px;
    height: 100%;
    margin-left: -120px;
  }

  .rno-17-media-panel-title {
    font-size: 16px;
  }



  .icon-bg-1 {
    background-image: url(https://cdn.panshi.qq.com/public/icon-bg-1-m.png);
  }

  .icon-bg-2 {
    background-image: url(https://cdn.panshi.qq.com/public/icon-bg-2-m.png);
  }

  .icon-bg-3 {
    background-image: url(https://cdn.panshi.qq.com/public/icon-bg-3-m.png);
  }

  .icon-bg-4 {
    background-image: url(https://cdn.panshi.qq.com/public/icon-bg-4-m.png);
  }

}




/* 单独一行的情况 */
.rno-17-media-panel.rno-17-panel-only .rno-17-media-panel-title-wrap {
  padding: 20px 56px 20px 20px;
}

.rno-17-media-panel.rno-17-panel-only .rno-17-media-panel-desc {
  max-width: 90%;
}

.rno-17-media-panel-info {
  font-size: 14px;
  color: #333;
  line-height: 24px;  
  margin-top: 8px;  
}

.rno-17-media-panel-btn-wrap {
  margin-top: 20px;
}

.rno-17-media-panel-btn-wrap .rno-btn {
  padding-left: 52px;
  padding-right: 52px;
}

.rno-17-media-panel-btn-wrap .rno-btn:hover {
  background-color: #00a4ff;
  color: #fff;
}

.rno-17-media-panel.rno-17-panel-only .rno-17-media-panel-hd-object {
  width: 128px;
  height: 128px;
  margin-top: -64px;
  right: -32px;
}

.rno-17-media-panel-btn-wrap .rno-link {
  margin-right: 12px;
}


@media screen and (max-width: 768px) {
  .rno-17-media-panel.rno-17-panel-only .rno-17-media-panel-hd-object {
    display: none;
  }

  .rno-17-media-panel.rno-17-panel-only .rno-17-media-panel-title-wrap {
    padding: 20px;
  }

  .rno-17-media-panel.rno-17-panel-only .rno-17-media-panel-desc {
    max-width: 100%;
  }

  .rno-17-media-panel.rno-17-panel-only .rno-17-media-panel-btn-wrap .rno-btn {
    width: 100%;
  }

  .rno-17-media-panel-title-wrap {
    padding: 16px 56px 16px 20px;
  }

  .rno-17-media-panel-btn-wrap {
    margin-top: 10px;
  }

}




























