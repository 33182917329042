@charset "UTF-8";

/* 垂直靠左不带边框 */
.rno-1-media-panel {
  box-sizing: border-box;
  display: block;
  text-align: center;
  font-size: 14px
}

.rno-1-media-panel-object {
  margin: 0 auto 20px;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px
}

.rno-1-media-panel-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 48px
}

.rno-1-media-panel-title {
  font-size: 18px;
  line-height: 28px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.rno-1-media-panel-desc {
  margin-top: 12px;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

.rno-1-media-panel-desc p {
  margin: 0
}

.rno-1-media-panel-links {
  margin-top: 10px;
  text-align: left;
}

.rno-1-media-panel-link {
  color: #00a4ff;
  text-decoration: none
}

.rno-1-media-panel-link:after {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
  width: 6px;
  height: 6px;
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent #00a4ff #00a4ff transparent;
  border-width: 0 1px 1px 0;
  transform: rotate3d(0,0,1,-45deg);
  transition: transform .3s ease-in-out;
}

.rno-1-media-panel-link:hover:after {
  transform: rotate3d(0,0,1,-45deg) translate3d(3px,3px,0);
}

.rno-1-media-panel-left {
  text-align: left
}

.rno-1-media-panel-left .rno-1-media-panel-object {
  margin-left: 0
}

@media screen and (max-width: 768px) {
  .rno-1-media-panel-m-horizontal {
    position: relative;
    padding-left: 60px;
    text-align: left;
  }

  .rno-1-media-panel-m-horizontal .rno-1-media-panel-object {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
  }
}


/* 移动端 */
@media (max-width: 768px) {
  .rno-1-media-panel {
    text-align: left;
    padding-left: 60px
  }

  .rno-1-media-panel .rno-1-media-panel-object {
    position: absolute;
    display: block;
    margin: 0 auto 20px
  }

  .rno-1-media-panel .rno-1-media-panel-body {
    padding-top: 0
  }

  .rno-1-media-panel .rno-1-media-panel-title {
    font-size: 16px
  }

  .rno-1-media-panel .rno-1-media-panel-desc {
    margin-top: 10px
  }

  /* 特征优势移动端居左 s */
  .rno-scheme-section .rno-1-media-panel-object {
    position: absolute;
  }

  .rno-scheme-section .rno-1-media-panel-body {
    /* padding-left: 60px; */
  }

  .rno-scheme-section.rno-1-media-panel {
    text-align: left;
  }

  /* 特征优势移动端居左 e */

  /* 三大部署模式居左 s */
  .rno-scheme-section-2 .rno-1-media-panel-object {
    position: absolute;
  }

  .rno-scheme-section-2 .rno-1-media-panel-body {
    /* padding-left: 60px; */
  }

  .rno-scheme-section-2.rno-1-media-panel {
    text-align: left;
  }

  /* 三大部署模式居左 e */

}