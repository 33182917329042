@charset "utf-8";

/* 表格 1 s */
.date-table-info-head-wrap {
  border: 1px solid #e2e6ed;
}

.date-table-info-head {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  position: relative;
}

.date-table-info-head th,
.date-table-info-body td {
  padding: 18px;
}

.date-table-info-head th > div {
  font-size: 16px;
  color: #333;
  line-height: 24px;
}

.date-table-info-head th {
  border-right: 1px solid #e2e6ed;
}

.date-table-info-head th:last-child {
  border-right: 0;
}

.date-table-info-body-wrap {
  border: 1px solid #e2e6ed;
  border-top: none;
  border-bottom: none;
}

.date-table-info-body td {
  text-align: center;
}

.date-table-info-body td {
  border-right: 1px solid #e2e6ed;
}

.date-table-info-body td:last-child {
  border-right: 0;
}

.date-table-info-body tr {
  border-bottom: 1px solid #e2e6ed;
}

.date-table-info-body {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  position: relative;
}

.date-table-info-head td > div {
  font-size: 16px;
  color: #333;
  line-height: 24px;
  border-bottom: 1px solid #333;
}

td.mod-2 {
  border: 1px solid #e2e6ed; 
  border-top: none;
}

tbody tr:last-child .mod-1 {
  border-bottom: none;
}

td.mod-1 {
  background-color: #fff;
}

td.mod-1 div {
  display: block;
  margin: 0 auto;
  font-size: 16px;
  color: #000;
  line-height: 20px;
  width: 16px;
}

.date-table-info-body tbody {
  border-bottom: 1px solid #e2e6ed;
}

.mod-3 {
  background-color: #f5f7fa;
}

.w-1 {
  width: 350px;
}

.w-2 {
  width: 120px;
}

.w-3 {
  width: 230px;
}



@media screen and (max-width: 768px) {
  .table-info-scrollbar {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-info-wrap {
    width: 650px;
  }

  .table-info-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 6px;
    border-radius: 6px;
    border-radius: 6px
  }

  .table-info-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    background-color: #fff
  }

  .table-info-scrollbar::-webkit-scrollbar-track {
    background-color: #fff
  }

  .date-table-info-head th,
  .date-table-info-body td {
    padding: 8px;
  }

  .date-table-info-head th > div {
    font-size: 14px;
  }

  .w-1 {
    width: 150px;
  }

  .w-2 {
    width: 50px;
  }

  .w-3 {
    width: 100px;
  }

  .date-table-info-body td.mod-2 {
    text-align: left;
  }
}

/* 表格 1 e */


/* 表格 2 s */
.qualification-table-info-head-wrap {
  background-color: #fff;
}

.qualification-table-info-head {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  position: relative;
}

.qualification-table-info-head th {
  padding: 18px;
  border: 1px solid #e2e6ed;
  border-bottom: none;
}

.qualification-table-info-head th > div {
  font-size: 16px;
  color: #333;
  line-height: 24px;
}

.qualification-table-info-body-wrap {
  background-color: #fff;
}

.qualification-table-info-body {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  position: relative;
}

.qualification-table-info-body td {
  padding: 18px;
  text-align: center;
  border: 1px solid #e2e6ed;
}

.qualification-table-info-body td > div {
  font-size: 16px;
  color: #333;
  line-height: 24px;
}

.icon-right {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+5q2j56Gu5aSH5Lu9IDE4PC90aXRsZT4KICAgIDxnIGlkPSLpobXpnaItMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Iuadg+ebiuivpuaDhSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0ODUuMDAwMDAwLCAtODExLjAwMDAwMCkiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxnIGlkPSLmraPnoa7lpIfku70tMTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0ODUuMDAwMDAwLCA4MTEuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cmVjdCBpZD0i55+p5b2iIiBmaWxsPSIjMDAwMDAwIiBvcGFjaXR5PSIwIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjwvcmVjdD4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSLot6/lvoQiIGZpbGw9IiMwMEJFN0UiIHBvaW50cz0iMTUuOTIgMy43NiAxNC43ODQgMi42MjQgNi4zMDQgMTEuMTEyIDEuMjA4IDYuMDI0IDAuMDggNy4xNTIgNi4zMDQgMTMuMzc2IDcuNDMyIDEyLjI0Ij48L3BvbHlnb24+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
}


@media screen and (max-width: 768px) {
  .qualification-table-info-head th > div {
    font-size: 14px;
  }

  .qualification-table-info-head th,
  .qualification-table-info-body td {
    padding: 8px;
  }

  .to-join .table-info-wrap {
    width: 420px;
  }
}

/* 表格 2 e */


































