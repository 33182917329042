@charset "utf-8";

/* 产品选购 */

/* 推荐子产品 */
.rno-21-media-panel {
  display: block;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  text-decoration: none;
  position: relative;

  /* top: 0; */
  transition: all .2s linear;
  box-shadow: 0 2px 4px rgba(3,27,78,.06);
  border: 1px solid #e5e8ed;    
  min-height: 242px;
}

@media screen and (min-width: 769px) {
  .rno-21-media-panel:hover {
    box-shadow: 0 4px 8px rgba(3,27,78,.12);

    /* top: -3px; */

    /* cursor: pointer; */
  }

  /* .rno-21-media-panel:hover .rno-21-media-panel-title{
        color: #00A4FF;
    } */
  .no-hover:hover {
    box-shadow: 0 2px 4px rgba(3,27,78,.06);
    top: inherit;
    cursor: default;
  }

  .no-hover:hover .rno-21-media-panel-title {
    color: #000;
  }
}

/* 头部 */
.rno-21-media-panel-hd {
  margin-bottom: 8px;
  padding-left: 40px;
  position: relative;
}

.rno-21-media-panel-object {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
}

.rno-21-media-panel-icon {
  width: 100%;
  height: 100%;
}

.rno-21-media-panel-title {
  vertical-align: middle;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}

/* 内容 */
.rno-21-media-panel-cont{

}

.rno-21-media-panel-desc {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}


/* 价格 */
.rno-21-product-prices {
  text-align: center;
  line-height: 32px;
  height: 32px;
  -webkit-font-smoothing: antialiased;
}

.rno-21-product-prices-num {
  color: #ff7800;
  font-size: 20px;
}

.rno-21-product-price-unit {
  color: #ff7800;
  font-size: 14px;
  line-height: 24px;
  margin-left: 4px;
}

.rno-21-product-original-price {
  color: #b0b0b0;
  font-size: 14px;
  line-height: 24px;
  margin-left: 4px;
}

.rno-market-info-panel .rno-market-prices-num {
  font-size: 20px;
}

/* 性能列表展示 */
.rno-21-media-panel-list {
  margin-top: 12px;
}

.rno-21-media-panel-list li {
  font-size: 14px;
  color: #333;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rno-21-media-panel-list li::before {
  content: '';
  width: 12px;
  height: 12px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIj4KICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgZmlsbC1ydWxlPSJub256ZXJvIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiPjwvcmVjdD4KICAgICAgICAgICAgPHBhdGggZD0iTTEuOTkyMzQwMTQsNS41OTQ4MDIyMiBMNC4zMzA4NTk1NCw3LjQxMTExNDM0IEwxMC4xNTIzNDc1LDIuMTc5Njc2MzUgQzEwLjE1MjM0NzUsMi4xNzk2NzYzNSAxMC41NDI5ODA2LDEuODMyNDgyNCAxMC44ODQ1MjA2LDIuMTAzMzUxMDYgQzEwLjk4NTg3NCwyLjE4NTQxNTA4IDExLjEwMzA2MzksMi40MTcyNjAzIDEwLjgzODU5NDgsMi43ODA1MjI3MyBMNC43NjAwMjgsOS43MjU1NDkzMyBDNC43NjAwMjgsOS43MjU1NDkzMyA0LjI5MzM3OTg4LDEwLjM0NzYyOSAzLjc0MDE1OTA0LDkuNzE4NjYyODQgTDEuMTE3NjM4ODcsNi4yNjM5Mzk2NSBDMS4xMTc2Mzg4Nyw2LjI2MzkzOTY1IDAuODA2MTg4MTk2LDUuNzk3Mzc5ODUgMS4xOTY4MjEyNCw1LjUxNjE4MTQ0IEMxLjMyNzIwODIsNS40MjE0OTIxOSAxLjYyNjUxNzU4LDUuMjc0MDA2NSAxLjk5MjM0MDE0LDUuNTk0MjI4MzQgTDEuOTkyMzQwMTQsNS41OTQ4MDIyMiBaIiBpZD0iUGF0aCIgZmlsbD0iIzAwQTRGRiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  display: inline-block;
  vertical-align: 0;
  margin-right: 6px;
}



/* 底部 */
.rno-21-media-panel-ft{
}

.rno-21-media-panel-ft .rno-link {
  font-size: 14px;
  color: #00a4ff;
  line-height: 24px;
}

/* 底部按钮排版 */
.rno-21-media-panel-btn-wrap {
  margin-top: 28px;
}

.rno-21-media-panel-btn-wrap .rno-link {
  margin-left: 12px;
}

@media screen and (min-width: 769px) {  
  .rno-21-media-panel-btn-wrap .rno-btn {
    width: 100%;
    max-width: 200px;
  }
}

.rno-21-media-panel-btn-wrap .rno-btn:hover {
  background-color: #0092ee;
  color: #fff;
}

@media screen and (max-width: 768px) {    
  /* 价格排版 */
  .rno-21-media-panel-ft {
    display: table;
    width: 100%;
  }

  .rno-21-product-prices,
  .rno-21-media-panel-btn-wrap {
    display: table-cell;
    vertical-align: middle;
  }

  .rno-21-product-prices {
    text-align: left;
    line-height: 24px;
  }

  .rno-21-media-panel-btn-wrap {
    width: 88px;
  }

  .rno-21-media-panel-btn-wrap .rno-btn {
    width: 100%;
    padding: 0;
    margin-top: 12px;
  }

  .rno-21-product-original-price {
    display: block;
    margin-left: 0;
  }

}




/* 清除底部边距 s */
@media screen and (min-width: 769px) {
  .rno-introduce-section .rno-lattice-card > .rno-lattice-cell {
    margin-bottom: 0;
  }
}

/* 清除底部边距 e */

/* 移动端 */

/* @media screen and (max-width: 768px) {
    .rno-product-selection-section .rno-lattice-card > .rno-lattice-cell:last-child{
        margin-bottom: 0;
    }
} */











/* 卡片版式2 */
.rno-22-media-panel {
  display: block;
  box-sizing: border-box;
  padding: 20px 0 20px 20px;
  background-color: #fff;
  text-decoration: none;
  position: relative;
  top: 0;
  transition: all .2s linear;
  box-shadow: 0 2px 4px rgba(3,27,78,.06);
  border: 1px solid #e5e8ed;
}

@media screen and (min-width: 769px) {
  .rno-22-media-panel:hover {
    box-shadow: 0 4px 8px rgba(3,27,78,.12);

    /* top: -3px; */

    /* cursor: pointer; */
  }

  .rno-22-media-panel:hover .rno-22-media-panel-title {
    color: #00a4ff;
  }

  .no-hover:hover {
    box-shadow: 0 2px 4px rgba(3,27,78,.06);
    top: inherit;
    cursor: default;
  }

  .no-hover:hover .rno-22-media-panel-title {
    color: #000;
  }
}

/* 头部 */
.rno-22-media-panel-hd {
  margin-bottom: 8px;
  padding-left: 40px;
  position: relative;
}

.rno-22-media-panel-object {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
}

.rno-22-media-panel-icon {
  width: 100%;
  height: 100%;
}

.rno-22-media-panel-title {
  vertical-align: middle;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}


.rno-22-media-panel-desc {
  font-size: 14px;
  line-height: 24px;
  color: #666;
  display: box;
  box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  max-height: 72px;
}

.rno-22-media-panel-body {
  width: 100%;
  display: table;
}

.rno-22-media-panel-main {
  display: table-cell;
  vertical-align: top;
  padding-right: 20px;
}

.rno-22-media-panel-sidebar {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-left: 1px dashed #ddd;
  width: 200px;
}

.rno-22-media-panel-btn-wrap .rno-btn {
  margin-top: 8px;
  min-width: 120px;
}


/* 价格 */
.rno-22-product-prices {
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.rno-22-product-prices-num {
  color: #ff7800;
  font-size: 20px;
}

.rno-22-product-price-unit {
  color: #ff7800;
  font-size: 14px;
  line-height: 24px;
  margin-left: 4px;
}

.rno-22-product-original-price {
  display: block;
  color: #b0b0b0;
  font-size: 14px;
  line-height: 24px;
}

/* 移动端 */
@media screen and (max-width: 768px) {
  .rno-22-media-panel {
    padding: 20px;
  }

  .rno-22-media-panel-body,
  .rno-22-media-panel-main {
    display: block;
  }

  .rno-22-media-panel-main {
    padding-right: 0;
  }

  .rno-22-media-panel-sidebar {
    display: table;
    border-left: none;
    width: 100%;
    border-top: 1px dashed #e5e5e5;
    padding-top: 12px;
    margin-top: 12px;
  }
    
  .rno-22-product-prices,
  .rno-22-media-panel-btn-wrap {
    display: table-cell;
    vertical-align: middle;
  }

  .rno-22-product-prices {
    text-align: left;
    line-height: 24px;
  }


  .rno-22-media-panel-btn-wrap {
    width: 88px;
  }

  .rno-22-media-panel-btn-wrap .rno-btn {
    width: 100%;
    min-width: inherit;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .rno-22-product-original-price {
    display: block;
    margin-left: 0;
  }




    

}

/* 单个卡片 */

/* 性能列表横排展示 */
.rno-22-media-panel-list {
  margin-top: 12px;
}

.rno-22-media-panel-list li {
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 160px;
  padding-right: 10px;
}

.rno-22-media-panel-list li::before {
  content: '';
  width: 12px;
  height: 12px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjYgKDY3NDkxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIj4KICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgZmlsbC1ydWxlPSJub256ZXJvIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiPjwvcmVjdD4KICAgICAgICAgICAgPHBhdGggZD0iTTEuOTkyMzQwMTQsNS41OTQ4MDIyMiBMNC4zMzA4NTk1NCw3LjQxMTExNDM0IEwxMC4xNTIzNDc1LDIuMTc5Njc2MzUgQzEwLjE1MjM0NzUsMi4xNzk2NzYzNSAxMC41NDI5ODA2LDEuODMyNDgyNCAxMC44ODQ1MjA2LDIuMTAzMzUxMDYgQzEwLjk4NTg3NCwyLjE4NTQxNTA4IDExLjEwMzA2MzksMi40MTcyNjAzIDEwLjgzODU5NDgsMi43ODA1MjI3MyBMNC43NjAwMjgsOS43MjU1NDkzMyBDNC43NjAwMjgsOS43MjU1NDkzMyA0LjI5MzM3OTg4LDEwLjM0NzYyOSAzLjc0MDE1OTA0LDkuNzE4NjYyODQgTDEuMTE3NjM4ODcsNi4yNjM5Mzk2NSBDMS4xMTc2Mzg4Nyw2LjI2MzkzOTY1IDAuODA2MTg4MTk2LDUuNzk3Mzc5ODUgMS4xOTY4MjEyNCw1LjUxNjE4MTQ0IEMxLjMyNzIwODIsNS40MjE0OTIxOSAxLjYyNjUxNzU4LDUuMjc0MDA2NSAxLjk5MjM0MDE0LDUuNTk0MjI4MzQgTDEuOTkyMzQwMTQsNS41OTQ4MDIyMiBaIiBpZD0iUGF0aCIgZmlsbD0iIzAwQTRGRiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  display: inline-block;
  vertical-align: 0;
  margin-right: 6px;
}

@media screen and (min-width: 769px) {
  .rno-22-media-panel.rno-only-panel .rno-22-media-panel-sidebar {
    width: 320px;
  }

  .rno-22-media-panel.rno-only-panel .rno-22-media-panel-btn-wrap .rno-btn {
    min-width: 200px;
  }
}


/* 配置参数 垂直列表 */
.rno-22-media-panel-info-wrap {
  display: table;
  width: 100%;
  margin-top: 16px;
}

.rno-22-media-panel-info {
  display: table-cell;
  width: 50%;
  border-right: 1px dashed #ddd;
  padding-right: 26px;
}

@media screen and (min-width: 769px) {
  .rno-22-media-panel-info:last-child {
    border-right: none;
    padding-left: 26px;
    padding-right: 0;
  }
}


.rno-22-media-panel-info-title {
  font-size: 16px;
  color: #000;
  line-height: 28px; 
  margin-bottom: 8px;
}

.rno-22-media-panel-info-list li {
  font-size: 14px;
  color: #333;
  line-height: 24px;
}
















/* 组合卡片 */
.rno-23-media-panel {
  display: block;
  box-sizing: border-box;
  padding: 28px;
  background-color: #fff;
  text-decoration: none;
  position: relative;
  top: 0;
  transition: all .2s linear;
  box-shadow: 0 2px 4px rgba(3,27,78,.06);
  border: 1px solid #e5e8ed;
}

@media screen and (min-width: 769px) {
  .rno-23-media-panel:hover {
    box-shadow: 0 4px 8px rgba(3,27,78,.12);

    /* top: -3px; */

    /* cursor: pointer; */
  }

  .rno-23-media-panel:hover .rno-23-media-panel-title {
    color: #00a4ff;
  }

  .no-hover:hover {
    box-shadow: 0 2px 4px rgba(3,27,78,.06);
    top: inherit;
    cursor: default;
  }

  .no-hover:hover .rno-23-media-panel-title {
    color: #000;
  }
}

.rno-23-media-panel-hd {
  text-align: center;
}

.rno-23-media-panel-title {
  font-size: 20px;
  line-height: 28px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  font-weight: normal;
}

.rno-23-media-panel-desc {
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  margin-top: 8px;
}


.rno-23-media-panel-body {
  margin-top: 40px;
}

.rno-23-media-panel-info-wrap{
    
}

@media screen and (min-width: 769px) {
  .rno-23-media-panel-info-list-3 .rno-23-media-panel-info {
    width: calc((100% - 20px * (2 - 1) - 1px)/ 3);
  }
}

.rno-23-media-panel-info {
  padding-left: 20px;
  display: inline-block;
  vertical-align: top;
  min-height: 116px;
  border-left: 1px dashed #ddd;
}

.rno-23-media-panel-info:first-child {
  border-left: none;
}

.rno-23-media-panel-info-title {
  font-size: 16px;
  color: #000;
  line-height: 28px;
  margin-bottom: 8px;   
}

.rno-23-media-panel-info-list li {
  font-size: 14px;
  color: #333;
  line-height: 24px;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}

.rno-23-media-panel-info-list li:first-child {
  margin-top: 0;
}




.rno-23-media-panel-footer {
  text-align: center;
  margin-top: 28px;
}

.rno-23-media-panel-btn-wrap {
  margin-top: 16px;
}

.rno-23-media-panel-btn-wrap .rno-btn {
  min-width: 240px;
}

/* 移动端 */
@media screen and (max-width: 768px) {
  .rno-23-media-panel {
    padding: 20px;
  }

  .rno-23-media-panel-hd {
    text-align: left;
  }

  .rno-22-media-panel-info-wrap {
    display: block;
  }

  .rno-22-media-panel-info {
    display: table;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    border-right: none;
    margin-bottom: 18px;
  }

  .rno-22-media-panel-info:last-child {
    margin-bottom: 0;
  }

  .rno-22-media-panel-info-title,
  .rno-22-media-panel-info-list {
    display: table-cell;
    vertical-align: top;
  }

  .rno-22-media-panel-info-title {
    width: 80px;
  }

}



/* 价格 */
.rno-23-product-prices {
  text-align: center;
  line-height: 32px;
  height: 32px;
  -webkit-font-smoothing: antialiased;
}

.rno-23-product-prices-num {
  color: #ff7800;
  font-size: 20px;
}

.rno-23-product-price-unit {
  color: #ff7800;
  font-size: 14px;
  line-height: 24px;
  margin-left: 4px;
}

.rno-23-product-original-price {
  color: #b0b0b0;
  font-size: 14px;
  line-height: 24px;
  margin-left: 4px;
}

/* 移动端 */
@media screen and (max-width: 768px) {
  .rno-23-media-panel-body {
    margin-top: 20px;
  }

  .rno-23-media-panel-info {
    display: table;
    border-left: none;
    padding-left: 0;
    width: 100%;
    min-height: inherit;
    margin-bottom: 18px;
  }

  .rno-23-media-panel-info:last-child {
    margin-bottom: 0;
  }

  .rno-23-media-panel-info-title,
  .rno-23-media-panel-info-list {
    display: table-cell;
    vertical-align: top;
  }

  .rno-23-media-panel-info-title {
    width: 80px;
  }

  .rno-23-media-panel-info-list{

  }

  .rno-23-media-panel-footer {
    display: table;
    width: 100%;
    border-top: 1px dashed #e5e5e5;
    padding-top: 12px;
    margin-top: 12px;
  }

  .rno-23-product-prices,
  .rno-23-media-panel-btn-wrap {
    display: table-cell;
    vertical-align: middle;
  }

  .rno-23-media-panel-btn-wrap {
    width: 88px;
  }

  .rno-23-media-panel-btn-wrap .rno-btn {
    width: 100%;
    min-width: inherit;
    padding-left: 0;
    padding-right: 0;
  }

  .rno-23-product-prices {
    text-align: left;
    line-height: 24px;
  }

  .rno-23-product-original-price {
    display: block;
    margin-left: 0;
  }

  .resource-bundle-wrap .rno-section-des .rno-link {
    display: block;
    margin-top: 4px;
  }


  .rno-21-media-panel-btn-wrap .rno-link {
    display: block;
    text-align: center;
    margin-left: 0;
    margin-top: 12px;
  }

}

