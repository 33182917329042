@charset "utf-8";

/* 接入指引 */
.rno-19-media-panel {
  box-sizing: border-box;
  display: block;
  text-align: left;
  font-size: 14px;
}

.rno-19-media-panel-body {
  width: 86%;
}

.rno-19-media-panel-step {
  display: flex;
  align-items: center;
  line-height: 32px;
  height: 32px;
}

.rno-19-media-panel-num {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  background-color: #0052D9;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  font-size: 16px;
  color: #FFF;
  line-height: 22px;
  margin-right: 2px;
}

.rno-19-media-panel-num:after {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  background-color: #F5F7FA;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rno-19-media-panel-title {
  color: #333;
  font-size: 16px;
  line-height: 22px;
  word-break: keep-all;
}

.rno-19-media-panel-desc {
  margin-top: 12px;
  font-size: 14px;
  color: #666;
  line-height: 24px;
}

.rno-19-media-panel-line {
  font-size: 0;
  height: 0;
  border-top: 1px solid #333;
  display: inline-block;
  vertical-align: 5px;
  width: 100px;
  position: relative;
  margin: 0 8px;
}

/* 链接 */
.rno-19-media-panel-links {
  margin-top: 12px;
}

.rno-19-media-panel-links .rno-link {
  display: block;
  margin-top: 4px;
}


/* 栅格调整 去除边距 */
@media screen and (min-width: 769px) {
  .rno-join-guide-section .rno-lattice .rno-lattice-cell {
    margin-right: 4px;
    margin-bottom: 30px;
  }

  .rno-join-guide-section .rno-lattice.rno-lattice-cols-5>.rno-lattice-cell {
    width: calc((100% - 4px * (5 - 1) - 1px)/ 5);
  }

  .rno-join-guide-section .rno-lattice.rno-lattice-cols-4>.rno-lattice-cell {
    width: calc((100% - 4px * (4 - 1) - 1px)/ 4);
  }

  .rno-join-guide-section .rno-lattice.rno-lattice-cols-3>.rno-lattice-cell {
    width: calc((100% - 4px * (3 - 1) - 1px)/ 3);
  }

  .rno-join-guide-section .rno-lattice.rno-lattice-cols-2>.rno-lattice-cell {
    width: calc((100% - 4px * (2 - 1) - 1px)/ 2);
  }

  .rno-join-guide-section .rno-lattice-cell:last-child .rno-19-media-panel-line {
    display: none;
  }
}

.rno-lattice-cell-wrapper {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between
}



/* 移动端左侧的步骤样式 */
@media screen and (max-width: 768px) {
  .rno-19-media-panel {
    margin-left: 8px;
    position: relative;
    padding-bottom: 23px;
  }

  .rno-19-media-panel:before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    bottom: -10px;
    left: -8px;
    border-left: 1px dashed #ddd;
  }

  .rno-19-media-panel:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 12px;
    left: -10px;
    width: 6px;
    height: 6px;
    border: 1px solid #cecece;
    background-color: #fff;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .rno-19-media-panel-line {
    display: none;
  }

  .rno-join-guide-section .rno-lattice-cell:last-child .rno-19-media-panel::before {
    display: none;
  }

  .rno-19-media-panel-step,
  .rno-19-media-panel-body {
    display: table-cell;
    vertical-align: top;
  }

  .rno-19-media-panel-step {
    padding-right: 12px;
  }

  .rno-19-media-panel-body {
    width: 100%;
  }

  .rno-join-guide-section .rno-lattice-cell:last-child .rno-19-media-panel {
    padding-bottom: 0;
  }

  .rno-join-guide-section .rno-lattice>.rno-lattice-cell {
    margin-bottom: 0;
  }

  .rno-join-guide-section .rno-section-subtitle {
    margin-top: 22px;
    margin-bottom: 20px;
  }

}











